import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import useAuthentication from '../authenticate';

const Ads = () => {

const user = useAuthentication();
const [loading, setLoading] = useState(true);

useEffect(() => {
  if(user){
    setLoading(false);
  }
}, [user, setLoading])

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>Ads - Stenz Digital</title>
      </Helmet>
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Ad Campaigns</header>
        {loading ? (
          <div className="mb-4">
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <p className="text-xl text-gray-400">Get verified to run ad campaigns</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <Link to='/kyc' className='bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700'>Get verified</Link>
                <Link to='/' className='bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700'>More information</Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Ads;