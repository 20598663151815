import React from 'react';
import { Link } from 'react-router-dom';

const Header = ({
  title,
  links,
  logo,
  textColor,
  fontSize,
  fontStyle,
  backgroundColor,
  cartButtonText,
  cartButtonFontSize,
  cartButtonFontStyle,
  cartButtonTextColor,
  cartButtonBackgroundColor,
}) => {
  return (
    <header className={`flex flex-wrap justify-between items-center px-4 sm:px-6 py-2 ${backgroundColor}`}>
      <div className="w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 flex justify-center">
        <Link to="/">
          {logo && (<img src={logo} alt={title} className="w-24" />)}
          {title && (<h1 className={`${textColor} ${fontSize} ${fontStyle}`}>{title}</h1>)}
        </Link>
      </div>

      <nav className="w-full sm:w-auto mb-4 sm:mb-0">
        <ul className="flex flex-col sm:flex-row justify-center text-center">
          {links.map((link, index) => (
            <li key={index} className="mb-2 sm:mb-0 mr-4">
              <Link to={link.url} className={`${fontStyle} ${fontSize} ${textColor}`}>
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className="w-full sm:w-auto mb-4 sm:mb-0 sm:items-center">
        <Link
          to="/cart"
          className={`block sm:inline-block px-4 py-2 text-center rounded-full ${cartButtonFontStyle} ${cartButtonFontSize} ${cartButtonBackgroundColor} ${cartButtonTextColor}`}
        >
          {cartButtonText}
        </Link>
      </div>
    </header>
  );
};

export default Header;
