import React, {useEffect, useState} from 'react';
import useAuthentication from '../../authenticate';
import { useParams } from 'react-router-dom';

const apiLink = process.env.REACT_APP_API_LINK;

const ProductSection = ({ title, priceColor, backgroundColor, textColor, titleFontSize, productFontSize, buttonBgColor, buttonTextColor, buttonTitle }) => {
  const [products, setProducts] = useState([]);
  const { sid } = useParams();
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState('DKK ');
  const user = useAuthentication();

  //const navigate = useNavigation();

  useEffect(() => {
    const fetchProducts = async () => {
      if(!user)
      {
        return;
      }
  
      try {
        const response = await fetch(apiLink + 'getProducts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId: user.uid, siteId: sid}),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const data = await response.json();
        const filteredProducts = data.products.filter(product => !product.archived);
        const currency = data.currency;
        if(currency)
        {
          setCurrency(currency);
        }

        if (response.ok)
        {
          setLoading(false);
          setProducts(filteredProducts);
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [user, sid]);

  const renderLoadingEffect = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {[...Array(3)].map((_, index) => (
        <div key={index} className={`p-4 border rounded ${textColor} animate-pulse`}>
          <div className={`mb-2 ${productFontSize} bg-gray-300 h-6 w-3/4`}></div>
          <div className="w-full h-48 mb-2 bg-gray-300"></div>
          <div className="mb-2 bg-gray-300 h-4 w-full"></div>
          <div className={`${priceColor} bg-gray-300 h-4 w-1/2`}></div>
          <div className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full bg-gray-300`}></div>
        </div>
      ))}
    </div>
  );

  const renderPlaceholder = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {[...Array(3)].map((_, index) => (
        <div key={index} className={`p-4 border rounded ${textColor}`}>
          <div className={`mb-2 ${productFontSize} bg-gray-300 h-6 w-3/4`}></div>
          <div className="w-full h-48 mb-2 bg-gray-300"></div>
          <div className="mb-2 bg-gray-300 h-4 w-full"></div>
          <div className={`${priceColor} bg-gray-300 h-4 w-1/2`}></div>
          <button className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full`}>{buttonTitle}</button>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`p-4 ${backgroundColor}`}>
      {title && <h2 className={`text-center ${titleFontSize} ${textColor} mb-4`}>{title}</h2>}
      {loading ? renderLoadingEffect() : (products.length === 0 ? renderPlaceholder() : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product, index) => (
            <div key={index} className={`p-4 border rounded ${textColor}`}>
              <h3 className={`mb-2 ${productFontSize}`}>{product.name}</h3>
              {product.image && (
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-auto mb-2 cursor-pointer"
                />
              )}
              <p className={`${priceColor}`}>{currency}{product.price}</p>
              <button className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full`}>{buttonTitle}</button>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ProductSection;
