import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate2';
import { useLanguage } from '../LanguageContext';

const Unrecognized = () => {
const user = useAuthentication();
const navigate = useNavigate();
const { translations } = useLanguage();


useEffect(() => {
  if(user)
  {
    navigate('/');
  }
}, [user, navigate])


  return (
    <>
    <div>
      <Helmet>
        <title>{translations.urla} - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <main className="text-center">
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-white">{translations.urla}</h1>
          <p className='text-white mt-4 text-lg'>{translations.unrecognized_login_attempt}</p>
        </section>
        <section className="mb-4">
          <Link
            to="/"
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            {translations.back}
          </Link>
        </section>
      </main>
    </div>
    </>
  );
};

export default Unrecognized;
