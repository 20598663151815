import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Message from '../Message';
import useAuthentication from '../../authenticate2';
import { Link, useNavigate } from 'react-router-dom';
import { ImSpinner2 } from "react-icons/im";
import { useLanguage } from '../../LanguageContext';

const subLink = process.env.REACT_APP_SUBSCRIBE_LINK;

const IntroCampaign = () => {
  const { translations } = useLanguage();
  const user = useAuthentication();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState('');

  const closeMessage = () => {
    setShowMessage(false);
  };

  const handlePlanSelection = (planId, planName) => {
    const subscribe = async (planId, planName) => {
      try {
        setIsLoading(true);
        const response = await fetch(subLink, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.uid, pid: planId, name: planName }),
        });

        const data = await response.json();

        if (!response.ok) {
          setMessage(translations.failed_init_pay + "(subscribe/failed)");
          setShowMessage(true);
          setIsLoading(false);
          return;
        }

        if (response.ok) {
          setSelectedPlan('');
          setSelectedPlanId('');
          setModalVisible(false);
          setIsLoading(false);
          window.location.replace(data.url);
        }
      } catch (error) {
        console.error(error);
        setMessage(translations.failed_init_pay + "(subscribe/internal)");
        setShowMessage(true);
        return;
      }
    };

    if (!user) {
      navigate('/register');
      return;
    }

    if(!selectedPlanId && !selectedPlan)
    {
      setSelectedPlan(planName);
      setSelectedPlanId(planId);
      setModalVisible(true);
      return;
    }

    subscribe(planId, planName);
  };

  const closeModal = () =>
  {
    setSelectedPlan('');
    setSelectedPlanId('');
    setModalVisible(false);
  }

  const PlanSelectionModal = ({ plan, planId }) => (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white text-black rounded-lg p-6 w-96">
        <h2 className="text-2xl font-bold mb-4">{translations.choose_billing_cycle}</h2>
        <p className="mb-6">{translations.select_billing_cycle.replace('{plan}', plan)}</p>
        <div className="grid gap-4">
          <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300 transition hover:scale-105 shadow" onClick={() => {
            setLoading('1');
            handlePlanSelection(planId + '_annual', plan);
            }}>
            {isLoading && loading === '1' ? (
            <ImSpinner2 className="animate-spin mx-auto text-2xl" />
          ) : (
            planId === 'starter' ? (
              <>
                {plan} {translations.starter_ann}
              </>
            ) : (
              `${plan} ${translations.pro_ann}`
            )
          )}</button>
          <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => {
            setLoading('2');
            handlePlanSelection(planId, plan);
            }}>
            {isLoading && loading === '2' ? (
            <ImSpinner2 className="animate-spin mx-auto text-2xl" />
          ) : (
            planId === 'starter' ? (
              <>
                {plan} {translations.starter_monthly}
              </>
            ) : (
              `${plan} ${translations.pro_monthly}`
            )
          )}</button>
        </div>
        <button className="mt-4 text-gray-500 hover:underline" onClick={() => closeModal()}>{translations.cancel}</button>
      </div>
    </div>
  );

  return (
    <>
      <div>
        <Helmet>
          <title>{translations.plans} - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex flex-col justify-center items-center min-h-screen text-white">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
          {showMessage ? (
            <Message message={message} onClose={closeMessage} />
          ) : (
            <div className="flex flex-col justify-center items-center min-h-screen p-4">
              <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
                {showMessage && <Message message={message} onClose={closeMessage} />}
                <h1 className="text-4xl font-semibold py-6 mt-10 lg:mt-0 mb-6">{translations.choose_plan}</h1>
                <div className="bg-yellow-500 text-white rounded-lg p-4 mb-8 shadow-lg">
                  <h2 className="text-2xl font-bold">{translations.intro_offer}</h2>
                  <p className="mt-2 text-lg">
                    {translations.starter_discount_offer.split("INTRO")[0]}
                    <span className="font-bold">INTRO</span>
                    {translations.starter_discount_offer.split("INTRO")[1]}</p>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-3 gap-8">
                  <div className="bg-gray-900 text-white rounded-lg p-8 flex flex-col shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">
                    <h2 className="text-2xl font-bold mb-4">Starter</h2>
                    <div className="mb-6">
                      <p className="text-gray-300 text-lg">
                        <span className="line-through">{translations.starter_org_price}</span> <span className="text-yellow-500">{translations.starter_new_price}</span>
                      </p>
                      <hr className="border-t border-gray-600 my-4" />
                      <p className='text-gray-300'>
                        {translations.starter_plan_features.split(', ').map((feature, index) => (
                          <span key={index}>
                            {feature}
                            {index < translations.starter_plan_features.split(', ').length - 1 && <br />}
                          </span>
                        ))}
                        <span className="font-bold underline hover:text-blue-400 cursor-pointer">
                          <br />
                          <Link to={'/plans/starter'} target='_blank' rel='noopener noreferrer'>
                            {translations.view_full_details}
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => handlePlanSelection("starter", "Starter")}>{translations.select_plan}</button>
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => window.open('https://stenzdigital.app/plans/starter', '_blank')}>{translations.learn_more}</button>
                    </div>
                  </div>
                  <div className="bg-blue-700 text-white rounded-lg p-8 flex flex-col transform scale-105 shadow-lg hover:shadow-xl hover:scale-110 transition-transform">
                    <h2 className="text-3xl font-bold mb-4">Pro</h2>
                    <div className="mb-6">
                      <p className="text-gray-200 text-md">{translations.pro_price}</p>
                      <hr className="border-t border-gray-200 my-4" />
                      <p className='text-gray-200'>
                        {translations.pro_plan_features.split(', ').map((feature, index) => (
                          <span key={index}>
                            {feature}
                            {index < translations.pro_plan_features.split(', ').length - 1 && <br />}
                          </span>
                        ))}
                        <span className="font-bold underline hover:text-blue-400 cursor-pointer">
                          <br />
                          <Link to={'/plans/pro'} target='_blank' rel='noopener noreferrer'>
                            {translations.view_full_details}
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-yellow-500 px-4 py-2 hover:bg-yellow-600 text-white font-bold rounded-lg focus:outline-none focus:ring focus:border-yellow-300" onClick={() => handlePlanSelection("pro", "Pro")}>{translations.select_plan}</button>
                      <button className="bg-yellow-500 px-4 py-2 hover:bg-yellow-600 text-white font-bold rounded-lg focus:outline-none focus:ring focus:border-yellow-300" onClick={() => window.open('https://stenzdigital.app/plans/pro', '_blank')}>{translations.learn_more}</button>
                    </div>
                  </div>
                  <div className="bg-gray-900 text-white rounded-lg p-8 flex flex-col shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform">
                    <h2 className="text-2xl font-bold mb-4">Enterprise</h2>
                    <div className="mb-6">
                      <p className="text-gray-300 text-lg">{translations.contact_sales}</p>
                      <hr className="border-t border-gray-600 my-4" />
                      <p className='text-gray-300'>
                        {translations.enterprise_plan_features.split(', ').map((feature, index) => (
                          <span key={index}>
                            {feature}
                            {index < translations.enterprise_plan_features.split(', ').length - 1 && <br />}
                          </span>
                        ))}
                        <span className="font-bold underline hover:text-blue-400 cursor-pointer">
                          <br />
                          <Link to={'/plans/enterprise'} target='_blank' rel='noopener noreferrer'>
                            {translations.view_full_details}
                          </Link>
                        </span>
                      </p>
                    </div>
                    <div className="mt-auto flex justify-between gap-4">
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() => window.open('https://stenzdigital.app/plans/enterprise', '_blank')}>{translations.select_plan}</button>
                      <button className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg focus:outline-none focus:ring focus:border-blue-300" onClick={() =>  window.open('https://stenzdigital.app/plans/enterprise', '_blank')}>{translations.learn_more}</button>
                    </div>
                  </div>
                </div>
                <p className="text-gray-500 text-sm mt-6">{translations.pricing_disclaimer}</p>
              </div>
            </div>
          )}
        </div>
        {modalVisible && <PlanSelectionModal plan={selectedPlan} planId={selectedPlanId} />}
      </div>
    </>
  );
};

export default IntroCampaign;
