import React, { useEffect, useState } from 'react';
import useAuthentication from '../authenticate2';
import { useLanguage } from '../LanguageContext';

const LangBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const user = useAuthentication();
  const { translations, language } = useLanguage();

  useEffect(() => {
    if (language === "da" && user) {
      setShowBanner(true);
    }
  }, [language, user]);

  useEffect(() => {
    // Detect if screen width is mobile-sized
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClose = () => setShowBanner(false);

  return (
    <>
      {showBanner && (
        isMobile ? (
          // Mobile Modal Version
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-blue-500 text-white p-6 rounded-lg shadow-lg max-w-xs w-full mx-4 text-center">
              <p className="text-lg font-medium mb-4">{translations.language_update}</p>
              <button
                onClick={handleClose}
                className="bg-white text-blue-500 px-4 py-2 rounded-full font-semibold hover:bg-gray-100 focus:outline-none"
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          // Desktop Banner Version
          <div
            onClick={handleClose}
            className="absolute p-8 top-4 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white px-3 py-2 rounded-lg shadow-lg transition-opacity duration-300 opacity-90 hover:opacity-100 cursor-pointer flex items-center space-x-2"
          >
            <p className="text-sm font-medium">{translations.language_update}</p>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
              className="text-white text-lg font-bold ml-2 focus:outline-none hover:text-gray-300"
              aria-label="Close"
            >
              ×
            </button>
          </div>
        )
      )}
    </>
  );
};

export default LangBanner;
