import React from 'react';
import ImageSection from './components/confel/ImageSection';

const config = {
  components: {
    HeroSection: {
      fields: {
        title: {
          label: "Title",
          type: "text",
        },
        subtitle: {
          label: "Subtitle",
          type: "text"
        },
        textColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
        enableButton: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Enable Button</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="true">Enabled</option>
                <option value="false">Disabled</option>
              </select>
            </div>
          ),
        },
        buttonLink: {
          label: "Button Link",
          type: "text"
        },
        buttonText: {
          label: "Button Text",
          type: "text"
        },
        buttonTextColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonBackgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
               <option value="bg-blue-500">Blue</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-orange-300">Orange</option>
              </select>
            </div>
          ),
        },
        buttonHoverColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Hover Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-blue-600">Blue</option>
                <option value="bg-red-600">Red</option>
                <option value="bg-orange-400">Orange</option>
              </select>
            </div>
          ),
        },       
      },
      defaultProps: {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        enableButton: "true",
        buttonText: "Button",
        buttonLink: "https://stnz.app/",
        buttonTextColor: "text-white",
        textColor: "text-black",
        backgroundColor: "bg-white",
        buttonBackgroundColor: "bg-blue-500",
        buttonHoverColor: "bg-blue-600",
      },
      render: ({ title, subtitle, enableButton, buttonText, buttonLink, buttonTextColor, textColor, backgroundColor, buttonBackgroundColor, buttonHoverColor }) => {
        const handleButtonClick = async () => {
          window.location.href = buttonLink;
        }

        return (
          <section className={`${backgroundColor} ${textColor} py-16`}>
            <div className="container mx-auto text-center">
              <h1 className="text-4xl font-bold mb-4">{title}</h1>
              <p className="text-lg mb-8">{subtitle}</p>
              {enableButton === "true" && (
                <>
                  <button onClick={handleButtonClick} className={`${buttonBackgroundColor} hover:${buttonHoverColor} ${buttonTextColor} font-bold py-2 px-4 rounded`}>
                    {buttonText}
                  </button>
                </>
              )}
            </div>
          </section>
        );
      },
    },
    ImageSection: {
      fields: {
        imageUrl: {
          type: "custom",
          render: ({ value, onChange }) => (
            <ImageSection value={value} onChange={onChange} />
          ),
        },
        altText: {
          label: "Alt Text",
          type: "text"
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
      },
      defaultProps: {
        imageUrl: "https://stenzdigital.com/img/stenzdigitallogo.png",
        altText: "Placeholder Image",
        backgroundColor: "bg-white",
      },
      render: ({ imageUrl, altText, backgroundColor }) => {
        return (
          <section className={`py-12 ${backgroundColor}`}>
            <div className="container mx-auto">
              <img src={imageUrl} alt={altText} className="mx-auto" />
            </div>
          </section>
        );
      },
    },
    videoSection: {
      fields: {
        url: {
          label: "Url",
          type: "text",
        },
        label: {
          type: "custom",
          render: () => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-xs font-medium mb-2">*Only embeds from YouTube allowed</label>
            </div>
          ),
        },      
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },      
      },
      defaultProps: {
        url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        backgroundColor: "bg-white",        
      },
      render: ({ url, backgroundColor }) => {
        if(!url.includes('youtube.com') && !url.includes('/embed'))
        {
          return;
        }
        return (
          <section className={`py-12 ${backgroundColor}`}>
            <div className="container mx-auto">
              <div className="aspect-w-16 aspect-h-9">
                {/* eslint-disable-next-line */}
                <iframe
                  src={url}
                  height={"640"}
                  className="w-full"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </section>
        );
      },
    },
    TextSection: {
      fields: {
        content: {
          type: "textarea",
          label: "Content",
        },
        textColor: {
          type: "custom",
          label: "Text Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        textAlignment: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Alignment</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-center">Center</option>
                <option value="text-left">Left</option>
                <option value="text-right">Right</option>
              </select>
            </div>
          ),
        },
        textSize: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Text Size</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-base">Medium</option>
                <option value="text-2xl">Extra Extra Large</option>
                <option value="text-xl">Extra Large</option>
                <option value="text-lg">Large</option>
                <option value="text-sm">Small</option>
                <option value="text-xs">Extra Small</option>
              </select>
            </div>
          ),
        },             
      },
      defaultProps: {
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        textColor: "text-black",
        textAlignment: "text-center",
        backgroundColor: "bg-white",
      },
      render: ({ content, textColor, textAlignment, backgroundColor, textSize}) => {
        return (
          <section className={`py-12 ${textColor} ${textAlignment} ${backgroundColor}`}>
            <div className="container mx-auto">
              <p className={`${textSize}`}>{content}</p>
            </div>
          </section>
        );
      },
    },
    FooterSection: {
      fields: {
        content: {
          type: "textarea",
          label: "Content",
        },
        textColor: {
          type: "custom",
          label: "Text Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">Text Color</label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          label: "Background Color",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">Background Color</label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        content: "© 2024 Stenz Digital - All Rights Reserved.",
        textColor: "text-black",
        backgroundColor: "bg-white",
      },
      render: ({ content, textColor, backgroundColor }) => {
        return (
          <>
          <footer className={`${backgroundColor} ${textColor} py-8`}>
            <div className="container mx-auto text-center">
              <p className={`font-semibold`}>{content}</p>
            </div>
          </footer>
          </>
        );
      },
    },
    button: {
      fields: {
        title: {
          label: "Button Text",
          type: "text",
        },
        buttonLink: {
          label: "Button Link",
          type: "text"
        },
        buttonTextColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Text Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonBackgroundColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Background Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
               <option value="bg-blue-500">Blue</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-orange-300">Orange</option>
              </select>
            </div>
          ),
        },
        buttonHoverColor: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Hover Color</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-blue-600">Blue</option>
                <option value="bg-red-600">Red</option>
                <option value="bg-orange-400">Orange</option>
              </select>
            </div>
          ),
        },
        buttonAlignment: {
          type: "custom",
          render: ({value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="color" className="block text-sm font-medium mb-2">Button Alignment</label>
              <select
                id="color"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-center">Center</option>
                <option value="text-left">Left</option>
                <option value="text-right">Right</option>
              </select>
            </div>
          ),
        },       
      },
      defaultProps: {
        title: "Button",
        buttonLink: "https://stnz.app/",
        buttonTextColor: "text-white",
        buttonBackgroundColor: "bg-blue-500",
        buttonHoverColor: "bg-blue-600",
        buttonAlignment: "text-center",
      },
      render: ({ title, buttonLink, buttonTextColor, buttonBackgroundColor, buttonHoverColor,  buttonAlignment }) => {
        const handleButtonClick = async () => {
          window.location.href = buttonLink;
        }

        return (
            <div className={`container mx-auto p-2 ${buttonAlignment}`}>
              <button onClick={handleButtonClick} className={`${buttonBackgroundColor} hover:${buttonHoverColor} ${buttonTextColor} font-bold py-2 px-4 rounded`}>
                {title}
              </button>
            </div>
        );
      },
    },
  },
};

export default config;
