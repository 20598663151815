import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BsPlus } from 'react-icons/bs';
import useAuthentication from '../authenticate';
import { getStorage, ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

const sanitizeMediaName = (name) => {
  return name.replace(/[^\w-]/g, '_').toLowerCase();
};

const MediaModal = ({ isOpen, onClose, onSelectMedia }) => {
  const user = useAuthentication();
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [showUploadMediaModal, setShowUploadMediaModal] = useState(false);
  const [mediaName, setMediaName] = useState('');
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaList, setMediaList] = useState([]);

  useEffect(() => {
    if (!isOpen || !user) return;

    const fetchMedia = async () => {
      const storage = getStorage();
      const storageRef = ref(storage, `media/${user.uid}`);
      
      try {
        const result = await listAll(storageRef);
        const mediaPromises = result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          return { mediaName: item.name, mediaUrl: url };
        });
        
        const media = await Promise.all(mediaPromises);
        setMediaList(media);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchMedia();
  }, [user, isOpen]);

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const handleUploadMedia = async () => {
  setShowUploadMediaModal(false);
    if (!mediaFile || mediaName === '') {
      setMessage('Please provide a media name and select a file to upload.');
      setShowMessage(true);
      return;
    }

    const sanitizedMediaName = sanitizeMediaName(mediaName);
    const storage = getStorage();
    const mediaId = uuidv4();
    const storageRef = ref(storage, `media/${user.uid}/${sanitizedMediaName || mediaId}`);
    
    try {
      await uploadBytes(storageRef, mediaFile);
      const url = await getDownloadURL(storageRef);

      setMessage(`Media "${mediaName}" uploaded successfully.`);
      setShowMessage(true);
      setMediaList([...mediaList, { mediaName: sanitizedMediaName, mediaUrl: url }]);
      setMediaName('');
      setMediaFile(null);
      setShowUploadMediaModal(false);
    } catch (error) {
      setMessage('Failed to upload media. Please try again later.');
      setShowMessage(true);
    }
  };

  const handleSelectMedia = (mediaUrl, mediaName) => {
    onSelectMedia(mediaUrl, mediaName);
    onClose();
  };

  const handleDeleteMedia = async (mediaName) => {
    const storage = getStorage();
    const storageRef = ref(storage, `media/${user.uid}/${mediaName}`);

    try {
      await deleteObject(storageRef);
      setMessage(`Media "${mediaName}" deleted successfully.`);
      setShowMessage(true);
      setMediaList(mediaList.filter(media => media.mediaName !== mediaName));
    } catch (error) {
      setMessage('Failed to delete media. Please try again later.');
      setShowMessage(true);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white w-full md:w-3/4 lg:w-1/2 xl:w-2/3 overflow-y-auto max-h-screen overflow-x-hidden">
        <Helmet>
          <title>Uploaded Media - Stenz Digital</title>
        </Helmet>
        {showMessage && (
          <div className="mb-4 h-2/3 p-4 py-8 rounded-lg bg-gray-800 border border-gray-600">
            <p>{message}</p>
            <button
              className="mt-2 bg-blue-600 rounded-lg py-1 px-3 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
              onClick={handleCloseMessage}
            >
              Close
            </button>
          </div>
        )}
        {!showMessage && (
          <>
            <header className="text-3xl font-semibold text-gray-300 mb-4">Your Media</header>
            {loading ? (
              <div className="mb-4">
                <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
                <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100%', height: '100px' }}></div>
                </div>
              </div>
            ) : (
              <>
                <div className="mb-4 flex flex-row justify-between">
                  <p className='font-bold underline hover:text-blue-500 cursor-pointer' onClick={() => setShowUploadMediaModal(true)}>Upload New</p>
                </div>
                <p className="text-xl text-gray-400 mb-4">Here is your media:</p>
                {mediaList && mediaList.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto max-h-96">
                    {mediaList.map((media, index) => (
                      <div key={index} className="border-2 border-gray-600 rounded-lg p-5">
                        <img src={media.mediaUrl} alt={media.mediaName} className="mb-2 w-full h-40 object-cover rounded" />
                        <p className="truncate">{media.mediaName}</p>
                        <button
                          className="mt-2 bg-green-600 mr-2 rounded-lg py-1 px-3 hover:bg-green-700 focus:outline-none focus:ring focus:border-green-300"
                          onClick={() => handleSelectMedia(media.mediaUrl, media.mediaName)}
                        >
                          Select
                        </button>
                        <button
                          className="mt-2 bg-red-600 rounded-lg py-1 px-3 hover:bg-red-700 focus:outline-none focus:ring focus:border-red-300"
                          onClick={() => handleDeleteMedia(media.mediaName)}
                        >
                          Delete
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-400">You have no media uploaded.</p>
                )}
                <div className="p-4 flex items-center">
                  <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => setShowUploadMediaModal(true)}>
                    <BsPlus size={50} />
                  </div>
                </div>
              </>
            )}
            <div className="pt-2 flex justify-between">
              <button onClick={onClose} className="font-bold underline hover:text-blue-500">
                Close
              </button>
            </div>
          </>
        )}
        {showUploadMediaModal && (
          <div className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
              <h2 className="text-2xl font-semibold mb-4">Upload New Media</h2>
              <form>
                <div className="mb-4">
                  <label htmlFor="mediaName" className="block text-sm font-medium mb-2">Media Name</label>
                  <input
                    type="text"
                    id="mediaName"
                    value={mediaName}
                    onChange={(e) => setMediaName(e.target.value)}
                    className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Enter media name"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="mediaFile" className="block text-sm font-medium mb-2">Select Media File</label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="mediaFile"
                    onChange={(e) => setMediaFile(e.target.files[0])}
                    className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    required
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={handleUploadMedia}
                    className="bg-blue-600 rounded-lg py-2 px-4 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowUploadMediaModal(false)}
                    className="bg-red-600 rounded-lg py-2 px-4 hover:bg-red-700 focus:outline-none focus:ring focus:border-red-300"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MediaModal;
