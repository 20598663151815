import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';

const Footer = () => {
const { translations } = useLanguage();

  return (
    <footer className="bg-gray-900 text-white p-4 text-center">
      <div>
        <p className='font-semibold'>{translations.copyright.replace('{{year}}', new Date().getFullYear())}</p>
      </div>
      <br/>
      <div className='font-bold'>
        <p>Stenz Digital</p>
        <p>7850, Stoholm, {translations.denmark}</p>
        <p>CVR: DK44687151</p>
      </div>
      <div>
          <Link to={'/legal'} target='_blank' rel='noopener noreferrer' className='font-bold underline hover:text-blue-500'>
            <p>{translations.legal}</p>
          </Link>

          <Link to={'/contact'} target='_blank' rel='noopener noreferrer' className='font-bold underline hover:text-blue-500'>
            <p>{translations.contact}</p>
          </Link>
      </div>
      <div>
        <p className='font-semibold'>{translations.made_in_denmark}</p>
      </div>
    </footer>
  );
};

export default Footer;
