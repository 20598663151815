import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaGlobe, FaUser, FaSignOutAlt, FaCreditCard } from 'react-icons/fa';
import './Header.css';
import useAuthentication from '../authenticate2';

const Header = () => {
  const user = useAuthentication();

  return (
    (user &&
    <header className="flex flex-col bg-gray-900 h-screen w-28 fixed left-0 top-0">
      <div className="flex flex-col items-center justify-center p-0.5">
        <NavLink
        to="/home"
        >
           <img
          src="/img/stenzdigitallogowhite.png"
          alt="Logo"
          className="w-32 h-auto rounded-full"
        ></img>
        </NavLink>
      </div>
      <nav className="flex flex-col flex-grow mt-8">
        {/*
        <NavLink
          to="/home"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700 active:bg-gray-700"
        >
          <FaHome className="w-6 h-6" />
        </NavLink>
        */}
        <NavLink
          to="/sites"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaGlobe className="w-6 h-6" />
        </NavLink>
        {/*
        <NavLink
          to="/email"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaEnvelope className="w-6 h-6" />
        </NavLink>
        <NavLink
          to="/ads"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaRocket className="w-6 h-6" />
        </NavLink>
        */}
        <NavLink
          to="/billing"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaCreditCard className="w-6 h-6" />
        </NavLink>
        <NavLink
          to="/account"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaUser className="w-6 h-6" />
        </NavLink>
        <NavLink
          to="/logout"
          className="text-white flex items-center justify-center p-4 rounded-full hover:bg-gray-700"
        >
          <FaSignOutAlt className="w-6 h-6" />
        </NavLink>
      </nav>
    </header>
    )
  );
};

export default Header;
