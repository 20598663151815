import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Privacy Policy - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <main className="text-center max-w-4xl w-full">
          <section className="mb-8 mt-20 xl:mt-12">
            <h1 className="text-4xl font-bold text-black">Privacy Policy</h1>
            <p className="text-gray-700 mt-2">Effective Date: 26/07/2024</p>
          </section>
          <section className="bg-white p-6 rounded-lg shadow-md max-h-[calc(100vh-160px)] overflow-y-auto text-left">
            <h2 className="text-2xl font-semibold">Introduction</h2>
            <p className="mt-2">
              Welcome to Stenz Digital. We value your privacy and are committed to protecting your personal data.
              This Privacy Policy outlines how we collect, use, and safeguard your information when you use our
              website, accessible at <a href="https://stenzdigital.app" className="text-blue-600">https://stenzdigital.app</a>.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Information We Collect</h2>
            <p className="mt-2">
              We collect the following types of information:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>Personal Information:</strong> When you create an account or interact with our services,
                we collect personal information such as your name, email address, and payment details.
              </li>
              <li>
                <strong>Usage Data:</strong> We collect data on how you use our website, including your IP address,
                browser type, pages visited, and the time spent on each page.
              </li>
              <li>
                <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar technologies to
                enhance your experience and analyze usage patterns.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">How We Use Your Information</h2>
            <p className="mt-2">
              We use the information we collect for the following purposes:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>To Provide and Improve Our Services:</strong> We use your data to deliver and enhance our
                services, including user authentication and payment processing.
              </li>
              <li>
                <strong>To Communicate with You:</strong> We may use your contact information to send updates,
                newsletters, and other information related to our services.
              </li>
              <li>
                <strong>To Analyze Usage:</strong> We use analytics to understand how our website is used and to
                improve its functionality.
              </li>
              <li>
                <strong>To Process Payments:</strong> We use Stripe to handle payment transactions securely.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">Service Partners</h2>
            <p className="mt-2">
              We may share your information with the following service partners to help us provide and improve our
              services:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>Firebase:</strong> We use Firebase for authentication purposes. Firebase collects and stores
                certain user information to facilitate the login process.
              </li>
              <li>
                <strong>Google Analytics:</strong> We use Google Analytics to collect data on how our website is used,
                which helps us understand user behavior and improve our services.
              </li>
              <li>
                <strong>Stripe:</strong> We use Stripe to handle payment processing. Stripe processes your payment
                information in accordance with its own privacy policy.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">Data Security</h2>
            <p className="mt-2">
              We implement a variety of security measures to maintain the safety of your personal information. We use
              industry-standard encryption technologies and secure server environments to protect your data.
            </p>

            <h2 className="text-2xl font-semibold mt-4">User Rights</h2>
            <p className="mt-2">
              You have the following rights regarding your personal information:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>Access:</strong> You can request access to the personal information we hold about you.
              </li>
              <li>
                <strong>Correction:</strong> You can request correction of any inaccuracies in your personal information.
              </li>
              <li>
                <strong>Deletion:</strong> You can request the deletion of your personal information, subject to
                certain legal exceptions.
              </li>
              <li>
                <strong>Opt-Out:</strong> You can opt out of receiving marketing communications from us at any time.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">Changes to This Privacy Policy</h2>
            <p className="mt-2">
              We may update this Privacy Policy from time to time. We will notify you of any significant changes by
              posting the new Privacy Policy on our website. Your continued use of our services after such changes
              constitutes your acceptance of the updated policy.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Contact Us</h2>
            <p className="mt-2">
              If you have any questions about this Privacy Policy or our data practices, please contact us at
              <a href="mailto:contact@stenzdigital.com" className="text-blue-600"> contact@stenzdigital.com</a>.
            </p>
          </section>
        </main>
      </div>
    </>
  );
};

export default PrivacyPolicy;
