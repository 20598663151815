import React, { useEffect, useState } from 'react';
import useAuthentication from '../authenticate2';
import { sendEmailVerification } from 'firebase/auth';
import auth from '../firebase';

const EmailVerificationNotice = () => {
  const user = useAuthentication();
  const [verificationSent, setVerificationSent] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setVerificationSent(true);
      setTimeout(() => {
        setShowBanner(false);
      }, 2000);
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  useEffect(() => {
    if (user && !user.emailVerified && !verificationSent) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [showBanner, user, verificationSent])
  

  return (
    <>
      {showBanner && (
        <div className={`fixed z-10 bottom-0 left-0 right-0 ${verificationSent ? 'bg-green-500' : 'bg-red-500'} text-white p-4 flex items-center justify-between shadow-md`}>
          <p className="text-lg">
            {verificationSent ? 'Email Verification Sent!' : 'Please verify your email address to unlock all features.'}
          </p>
          {!verificationSent && (
            <button className="font-bold underline hover:text-blue-300" onClick={sendVerificationEmail}>Resend Email</button>
          )}
        </div>
      )}
    </>
  );
};

export default EmailVerificationNotice;
