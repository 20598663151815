import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsPlus } from 'react-icons/bs';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import Message from './Message';
import MediaModal from './Media';

const apiLink = process.env.REACT_APP_API_LINK;
const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK

const ViewProducts = () => {
  const user = useAuthentication();

  const { sn, sid } = useParams();
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [originalCurrency, setOriginalCurrency] = useState('' || 'DKK ');
  const [currency, setCurrency] = useState('' || 'DKK ');
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [productDescription, setProductDescription] = useState('');
  const [productSizes, setProductSizes] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productImageName, setProductImageName] = useState('');
  const [error, setError] = useState(false);
  const [products, setProducts] = useState([]);
  const [productCreated, setProductCreated] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [archivedProducts, setArchivedProducts] = useState([]);
  const [showArchive, setShowArchive] = useState(false);
  const [customDomain, setCustomDomain] = useState('');
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        const template = siteFound?.template;
        const currency = siteFound?.currency;
          
        if (!siteFound || !template || template !== 'e-commerce') {
          navigate(`/sites/${sn}/${sid}`);
        }

        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomain(siteFound?.customDomain);
        }

        if (currency)
        {
          setOriginalCurrency(currency);
          setCurrency(currency);
        }
      } catch (error) {
        console.error(error);
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    const fetchData = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        
        if(!siteFound?.hosted)
        {
          navigate(`/sites/${sn}/${sid}`);
        }

        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if (data.status && sn === siteFound.name) {
          await fetchUserSites();
          setLoading(false);
        } else {
          navigate(`/sites/${sn}/${sid}`);
        }
      } catch (error) {
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    // Fetch site data initially
    fetchData();

  }, [sid, sn, navigate, user]);

  const fetchProducts = async () => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;

      const response = await fetch(apiLink + 'getProducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId }),
      });

      if (!response.ok) {
        setLoading(false);
        return;
      }

      const data = await response.json();
      const filteredProducts = data.products.filter(product => !product.archived);
      const archivedProducts = data.products.filter(product => product.archived);
      setProducts(data.products);
      setFilteredProducts(filteredProducts);
      setArchivedProducts(archivedProducts);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (!user.emailVerified) {
        setMessage('Please verify your email address to unlock all features.');
        setShowMessage(true);
      } else {
        fetchProducts();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (productCreated) {
      setProductCreated(false);
      return;
    }
    if (!user.emailVerified || error) {
      navigate('/home');
    }
  };

  const handleOpenModal = () => {
    setIsMediaModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsMediaModalOpen(false);
  };

  const handleSelectMedia = (mediaUrl, mediaName) => {
    setProductImage(mediaUrl); 
    setProductImageName(mediaName);
    setIsMediaModalOpen(false);
  };

  const handleCreateProduct = async () => {
    try {

      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;

      setProductName(productName);

      let pSizes
      if(productSizes)
      {
        pSizes = productSizes.split(',').map(size => size.trim());
      }
      else
      {
        pSizes = null;
      }

      const response = await fetch(apiLink + 'createProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId, productName, productPrice, productDescription, productSizes: pSizes, productImage }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(`Failed to create your product. Please try again later. (${data.error})`);
        setShowCreateProductModal(false);
        setShowMessage(true);
        return;
      }

      setMessage(`Your product ${productName} was created successfully.`);
      setProductCreated(true);
      setShowMessage(true);
      setShowCreateProductModal(false);
      fetchProducts(user);
    } catch (error) {
      setMessage('Failed to create your product. Please try again later. (product/create-failed)');
      setError(true);
      setShowCreateProductModal(false);
      setShowMessage(true);
    }
  };

  const isCreateProductButtonDisabled = productName === '' || productPrice === '' || productDescription === '' || productImage === '';
  const isCurrencyButtonDisabled = currency === '';


  const handleSearch = () => {
    if (searchTerm && products) {
      let filtered;
      if(showArchive && archivedProducts)
      {
        filtered = archivedProducts.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      else
      {
        filtered = products.filter(product =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    if(showArchive && archivedProducts)
    {
      setFilteredProducts(archivedProducts);
      return;
    }
    setFilteredProducts(products);
  };

  const handleViewArchive = () => {
    if (products && archivedProducts) {
      setShowArchive(true);
      setFilteredProducts(archivedProducts);
    }
  };

  const handleCloseArchive = () => {
    if (products && archivedProducts) {
      setShowArchive(false);
      setFilteredProducts(products.filter(product => !product.archived));
    }
  };

  const handleCurrency = async () => {
    try {
      const response = await fetch(apiLink + 'setCurrency', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: sid, currency: currency }),
      });
      const data = await response.json();
      if (data.error)
      {
        setMessage(`Failed to set currency. Please try again later. (${data.error})`)
        setShowMessage(true);
      }
      if (response.ok)
      {
        setOriginalCurrency(currency);
        setMessage(`Successfully set currency to ${currency}`);
        setShowCurrencyModal(false);
        setShowMessage(true);
      }
    } catch (error) {
      setMessage(`Failed to set currency. Please try again later. (currency/internal-error)`)
      setShowMessage(true);
    }
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen text-white">
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage && (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      )}
      {isMediaModalOpen && (
        <MediaModal
          isOpen={isMediaModalOpen}
          onClose={handleCloseModal}
          onSelectMedia={handleSelectMedia}
        />
      )}
      {!showMessage && (
        <div className="bg-gray-900 rounded-lg shadow-lg mt-12 lg:mt-0 p-8 text-white w-full md:w-3/4 lg:w-1/2 xl:w-2/3 overflow-y-auto max-h-screen overflow-x-hidden">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Your Products</header>
        {loading ? (
          <div className="mb-4">
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
            <div className='pt-4'>
              <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100%', height: '100px' }}></div>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4 flex flex-row justify-between">
              <p className='font-bold underline hover:text-blue-500 cursor-pointer' onClick={() => setShowCreateProductModal(true)}>Create New</p>
              <p className='font-bold underline hover:text-blue-500 cursor-pointer' onClick={() => setShowCurrencyModal(true)}>Currency Settings</p>
            </div>
            <p className="text-xl text-gray-400">Here are your products:</p>
            {user && products && products.length > 0 ? (
              <>
                  <>
                  <div className="flex items-center mb-4">
                    <input
                      type="text"
                      placeholder="Find product by name"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="bg-gray-700 rounded-lg py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300 w-full"
                    />
                    <button
                      onClick={handleSearch}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg ml-2 focus:outline-none focus:ring focus:border-blue-300"
                    >
                      Search
                    </button>
                  </div>
                  <div className="mb-4">
                    <p className="font-bold underline hover:text-blue-500 cursor-pointer" onClick={() => handleClearSearch()}>Clear Search</p>
                  </div>
                  </>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 overflow-y-auto max-h-96">
                    {filteredProducts.map((product) => (
                      <div key={product.productId} className="border-2 border-gray-600 rounded-lg p-5">
                        <Link className='text-gray-300 cursor-pointer hover:text-blue-500' to={`/sites/${sn}/${sid}/dashboard/products/${product.productId}`}>
                          <img src={product.image} alt={product.name} className="mb-2 w-full h-40 object-cover rounded" />
                          <p className="truncate">{product.name}</p>
                        </Link>
                        <p className="text-gray-400">{originalCurrency}{product.price}</p>
                        {product.archived && (
                          <p className="text-yellow-500 font-bold">Archived</p>
                        )}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <p className="text-gray-400">Your site has no products.</p>
              )}
            <div className="p-4 flex items-center">
              <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => setShowCreateProductModal(true)}>
                <BsPlus size={50} />
              </div>
            </div>
          </>
        )}
        <div className="pt-2 flex justify-between">
          <Link to={`/sites/${sn}/${sid}/dashboard`}>
            <p className="font-bold underline hover:text-blue-500">Back</p>
          </Link>
          {products && (
            <p className="font-bold underline hover:text-blue-500 cursor-pointer" onClick={() => {!showArchive ? handleViewArchive() : handleCloseArchive()}}>{!showArchive ? 'Archive' : 'Active Products'}</p>
          )}
        </div>
      </div>
      )}

      {showCreateProductModal && (
        <div className="fixed inset-0 z-2 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Create New Product</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="productName" className="block text-sm font-medium mb-2">Product Name*</label>
                <input
                  type="text"
                  id="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productPrice" className="block text-sm font-medium mb-2">Product Price*</label>
                <input
                  type="number"
                  id="productPrice"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product price"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productSizes" className="block text-sm font-medium mb-2">Product Sizes</label>
                <input
                  type="text"
                  id="productSizes"
                  value={productSizes}
                  onChange={(e) => setProductSizes(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product sizes (e.g., S, M, L)"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productDescription" className="block text-sm font-medium mb-2">Product Description*</label>
                <textarea
                  type="text"
                  id="productDescription"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300 resize-none"
                  placeholder="Enter product description"
                  rows={4}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productImage" className="block text-sm font-medium mb-2">Product Image*</label>
                <Link to={productImage} target='_blank' className='font-bold underline hover:text-blue-500'>{productImageName}</Link>
              </div>
              <div className="mb-4">
                <div className='cursor-pointer mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300' onClick={handleOpenModal}>
                  {!productImage ? 'Choose Image' : 'Change Image'}
                </div>
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowCreateProductModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="button" onClick={handleCreateProduct} disabled={isCreateProductButtonDisabled} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Create Product</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showCurrencyModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Currency</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="currency" className="block text-sm font-medium mb-2">Currency</label>
                <select
                  id="currency"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  required
                >
                  <option disabled value="">Choose a currency</option>
                  <option value="DKK ">DKK - Danish Krone</option>
                  <option value="SEK ">SEK - Swedish Krona</option>
                  <option value="NOK ">NOK - Norwegian Krone</option>
                  <option value="$">USD - US Dollar</option>
                  <option value="€">EUR - Euro</option>
                  <option value="£">GBP - British Pound</option>
                  <option value="JPY ">JPY - Japanese Yen</option>
                  <option value="CNY ">CNY - Chinese Yuan</option>
                  <option value="AUD ">AUD - Australian Dollar</option>
                  <option value="CAD ">CAD - Canadian Dollar</option>
                  <option value="CHF ">CHF - Swiss Franc</option>
                  <option value="NZD ">NZD - New Zealand Dollar</option>
                </select>
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowCurrencyModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="button" onClick={handleCurrency} disabled={isCurrencyButtonDisabled} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Set Currency</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProducts;
