import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import { signInWithEmailAndPassword } from 'firebase/auth';
import auth from '../firebase';
import Message from './Message';
import BadWordsFilter from 'bad-words';

const apiLink = process.env.REACT_APP_SITE_EDITOR_LINK;
const apiLink2 = process.env.REACT_APP_API_LINK;

const Settings = () => {
  const user = useAuthentication();
  const { sn, sid } = useParams();
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [showChangeSiteNameModal, setShowChangeSiteNameModal] = useState(false);
  const [showDeleteSiteModal, setShowDeleteSiteModal] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState('');
  const [siteName, setSiteName] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [message, setMessage] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [goHome, setGoHome] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {  
    const fetchStatus = async () => {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
  
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);

      if(!siteFound?.hosted)
      {
        navigate(`/sites/${sn}/${sid}`);
        return;
      }

      if (siteFound && siteFound?.customDomain)
      {
        setCustomDomain(siteFound?.customDomain);
      }

      try {
        const response = await fetch(apiLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if(data.status && sn === siteFound.name)
        {
          setStatus(data.status);
          setLoading(false);
          return;
        }
        else
        {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
      } catch (error) {
        console.error('Error fetching site status:', error);
      }
    };
  
    // Fetch status initially
    fetchStatus();
  }, [sid, sn, status, navigate, user]);
  
  const handleViewDetails = async () => {
    navigate(`/sites/${sn}/${sid}/details`);
  }

  const handleViewDomainManagement = async () => {
    navigate(`/sites/${sn}/${sid}/domain`);
  }

  const handleChangeSiteName = async () => {
    try {
      //Profanity filter
      const filter = new BadWordsFilter();
      if(filter.isProfane(siteName))
      {
        setMessage('Detected profanity in site name. Please refer to our Terms of Service.');
        setShowChangeSiteNameModal(false);
        setShowMessage(true);
        return;
      }

      const cleanSiteName = async () => {
        return siteName.replace(/\s/g, '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      }

      // Check if site name meets the new rules
      if (cleanSiteName.length < 3 || !/[a-zA-Z]/.test(cleanSiteName)) {
        setMessage('Site names must be at least 3 characters long and include letters.');
        setShowChangeSiteNameModal(false);
        setShowMessage(true);
        return;
      }

      const csn = await cleanSiteName();
      setSiteName(csn);

      const response = await fetch(apiLink + 'changeSiteName', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sid, newSiteName: siteName }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(`Failed to change site name. Please try again later. (${data.error})`);
        setShowChangeSiteNameModal(false);
        setShowMessage(true);
        return;
      }

      setNameChanged(true);
      setMessage(`Your site name was changed successfully. New site name: ${siteName}`);
      setShowMessage(true);
      setShowChangeSiteNameModal(false);
    } catch (error) {
      setMessage('Failed to change site name. Please try again later. (site/name-change-failed)');
      setShowChangeSiteNameModal(false);
      setShowMessage(true);
      return;
    }
  };

  const handleCloseMessage = () => {
    if(nameChanged)
    {
      navigate(`/sites/${siteName}/${sid}/settings`);
    }
    if(goHome)
    {
      navigate('/sites');
      setGoHome(false);
    }
    setShowMessage(false);
  };

  const handleInputChange = (e) => {
    setConfirmationInput(e.target.value);
  };

  const isConfirmationValid = () => {
    return confirmationInput === sn && email && password;;
  };

  const handleDeleteSite = async () => {
    try {
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);

      // Delete site
      const response = await fetch(apiLink2 + 'deleteSite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: sid }),
      });

      const data = await response.json();
      if(data.error)
      {
        setMessage(`Failed to delete your site. (${data.error})`)
        setShowMessage(true);
        return;
      }

      setGoHome(true);
      setMessage('Your site has successfully been permanently deleted.');
      setShowMessage(true);
    } catch (error) {
      setMessage(`Failed to delete your site. (${error.code})`);
      setShowMessage(true);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleDeleteSite(email, password);
      setShowDeleteSiteModal(false);
    } catch (error) {
      setMessage(`Failed to verify your information. Please try again later. (${error.code})`);
      setShowMessage(true);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage && (
      <Message
        message={message}
        onClose={handleCloseMessage}
      />
    )}
    {!showMessage && (
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Site Settings</header>
        <div className="mb-4">
        {loading ?
            <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
            </div>  :
            <p className="text-xl text-gray-400">
            <Link
              target={status === 'Published' ? `_blank` : ''}
              to={status === 'Published' ? (customDomain ? customDomain : `https://${sn}.stnz.app`) : ''}
              className={`font-bold underline hover:text-blue-500 ${status !== 'Published' && 'cursor-not-allowed'}`}
            >
              {customDomain ? customDomain.replace('https://', '') : sn }
            </Link>
          </p>
          }
          <div className='flex items-center mb-4 text-xl text-gray-400'>
          <p>Status:</p>
            {loading ? 
            <div className="ml-2">
            <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
            </div>
            </div> : 
            <div className='ml-2'>
              <p className={`${status === 'Published' ? 'text-green-400' : status === 'Unpublished'  ? 'text-yellow-400' : 'text-red-400'}`}>{status}</p>
            </div>}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4">
          <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-not-allowed hover:bg-gray-700">Change Name</div>
          </div>
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer" onClick={handleViewDomainManagement}>Domain Management</div>
          </div>
          <div className="p-4">
            <div className={`bg-gray-600 p-4 rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={handleViewDetails}>Site Details</div>
          </div>
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-red-400 cursor-not-allowed hover:bg-gray-700" >Delete Site</div>
          </div>
        </div>
        <div className='pt-2'>
          <Link to={`/sites/${sn}/${sid}`}>
            <p className='font-bold underline hover:text-blue-500'>Back</p>
          </Link>
        </div>
      </div>
      )}
      {showChangeSiteNameModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Change Site Name</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="siteName" className="block text-sm font-medium mb-2">New Site Name</label>
                <input
                  type="text"
                  id="siteName"
                  value={siteName}
                  onChange={(e) => setSiteName(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter new site name"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowChangeSiteNameModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="button" onClick={handleChangeSiteName} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Change Name</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showDeleteSiteModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
          <h2 className="text-2xl font-semibold mb-4">Delete Site</h2>
          <p className='text-lg'>Are you sure you want to <span className='font-semibold'>permanently delete</span> the site <span className='font-bold'>{sn}{customDomain ? `/${customDomain.replace('https://', '')}` : ''}</span>?</p>
          <div className="mt-4">
            <p className='text-sm'>Site name: <span className='font-semibold'>{sn}</span></p>
            <label htmlFor="siteNameConfirmation" className="block text-sm font-medium mb-2">Type the site name to confirm:</label>
            <input
              type="text"
              id="siteNameConfirmation"
              value={confirmationInput}
              onChange={handleInputChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter site name"
            />
          </div>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium mb-2">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter your password"
                required
              />
            </div>
            <p className='bg-red-500 text-lg text-center m-4'>This action is irreversible</p>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowDeleteSiteModal(false)}
                className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!isConfirmationValid()}
                className={`bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300 ${!isConfirmationValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
      )}
    </div>
  );
};

export default Settings;
