import { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail } from 'firebase/auth';
import auth from './firebase';
import useUpdateAuth from './updateAuth';
import { useLanguage } from './LanguageContext';
import AutoLang from './AutoLang';

const useAuthentication = () => {
  const [user, setUser] = useState(null);
  const [linkSent, setLinkSent] = useState(false); // Track if link has been sent
  useUpdateAuth();
  AutoLang();

  const apiLink = process.env.REACT_APP_API_LINK;

  const navigate = useNavigate();
  const location = useLocation();
  const { changeLanguage } = useLanguage();

  const fetchUser = async (user) => {
    try {
      const response = await fetch(apiLink + 'getUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }),
      });

      if (!response.ok) {
        const { error } = await response.json();
        if (error === 'Unrecognized login attempt') {
          // Check if link has already been sent
          if (!linkSent) {
            const acs = {
              url: 'https://stenzdigital.app',
              handleCodeInApp: true,
            };
            await sendSignInLinkToEmail(auth, user.email, acs);
            setLinkSent(true); // Set the flag to true after sending the link
            
            if (user) {
              await signOut(auth);
            }
            navigate('/login/unrecognized');
            return;
          }
        } else {
          navigate('/login/unrecognized');
          return;
        }
      }

      const { user: userData, acountry} = await response.json();

      // Merge
      const updatedUser = { 
        ...user, 
        user: userData,
        acountry: acountry
    };
      setUser(updatedUser);

      // Set the language based on user data or country
      if (userData.lang) {
        changeLanguage(userData.lang);
      } else if (userData.country || acountry) {
        if (userData.country === 'DK' || acountry === 'DK') {
          changeLanguage('da');
        }
      }
    } catch (error) {
      console.error('Error during fetchUser:', error);
      navigate('/login/unrecognized');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        console.log('User is signed in');
        if (['/', '/login', '/register'].includes(location.pathname)) {
          navigate('/home');
        }
        await fetchUser(user);
      } else {
        setUser(null);
        console.log('User is not signed in');
        setLinkSent(false);
        if (!['/', '/login', '/register', '/404', '/e', '/e/verify', '/reset-password'].includes(location.pathname)) {
          navigate('/');
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname]);

  return user;
};

export default useAuthentication;