import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Message from './Message';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import auth from '../firebase';

const ChangePassword = () => {

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [changeFailed, setChangeFailed] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useRef(false);
  const msg = 'Password has been successfully updated. You can now login.';

  useEffect(() => {
    const changePass = async () => {
      try {
        const token = new URLSearchParams(location.search).get('oobCode');
        if (!token) {
          setLinkInvalid(true);
          setMessage(`Invalid Link. Please try again. (no-token)`);
          return;
        }
      } catch (error) {
        setChangeFailed(true);
        setMessage(`Failed to change password. Please try again. (${error.code})`);
      }
    };

    if (!isMounted.current) {
      // Check if component is mounted before running effect
      isMounted.current = true;
      changePass();
    }
  }, [location.search]);

  const closeMessage = () => {
    if(linkInvalid)
    {
      setLinkInvalid(false);
      navigate('/')
    }
    if(changeFailed)
    {
      setChangeFailed(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if(formData.password !== formData.confirmPassword)
    {
      setChangeFailed(true);
      setMessage(`An error occurred. Please try again. (passwords-do-not-match)`);
      return;
    }

    try {
      // Change Password
      const token = new URLSearchParams(location.search).get('oobCode');
      await confirmPasswordReset(auth, token, formData.password);

      // Password change successful
      navigate(`/e/?msg=${encodeURIComponent(msg)}`);
    } catch (error) {
      setChangeFailed(true);
      setMessage(`Failed to change password. Please try again. (${error.code})`);
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Change Password - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen">
        {changeFailed || linkInvalid ?  (
          <Message message={message} onClose={closeMessage} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="bg-gray-900 rounded-lg shadow-lg p-8 text-white"
          >
            <h2 className="text-3xl font-semibold mb-6">Change Password</h2>
            <div className="mb-6">
              <label htmlFor="password" className="text-sm font-medium mb-2">
                New Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter your password"
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="text-sm font-medium mb-2">
                Confirm New Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Confirm your password"
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              >
                Confirm
              </button>
            </div>
            <div className='pt-2'>
                <Link to='/'>
                  <p className='font-bold underline hover:text-blue-500'>Back</p>
                </Link>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ChangePassword;
