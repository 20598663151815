import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import auth from '../firebase';
import { signOut } from 'firebase/auth';


const EmailHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [messageContent, setMessageContent] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');
    const msg = queryParams.get('msg');

    const handleEmail = async () => {
      try {
        if (auth.currentUser) {
          await signOut(auth);
        }

        if (msg) {
          setMessageContent(decodeURIComponent(msg));
        } else {
          if (!mode) {
            setMessageContent('Invalid link');
          } else {
            if (mode.includes('recoverEmail') || mode.includes('verifyAndChangeEmail'))
            {
              setMessageContent('Redirecting...');
              navigate(`/e/verify?oobCode=${oobCode}&mode=${mode}`);
            }
            else if (mode.includes('verifyEmail')) {
              setMessageContent('Redirecting...');
              navigate(`/e/verify?oobCode=${oobCode}`);
            } else if (mode.includes('resetPassword')) {
              setMessageContent('Redirecting...');
              navigate(`/e/reset?oobCode=${oobCode}`);
            } 
            else if (mode.includes('signIn')) {
              setMessageContent('Redirecting...');
              navigate(`/e/signIn?oobCode=${oobCode}`);
            } 
            else {
              setMessageContent('Invalid link');
            }
          }
        }
      } catch (error) {
        setMessageContent(`An error occurred (${error})`);
      }
    };

    handleEmail();
  }, [location.search, navigate]);

  const handleCloseMessage = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <h2 className="text-3xl font-semibold mb-6">Message</h2>
        <div className="mb-6">
          <p className="text-white-500">{messageContent}</p>
        </div>
        <button
          onClick={handleCloseMessage}
          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EmailHandler;
