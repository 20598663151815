import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Message from './Message';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import auth from '../firebase';
import useAuthentication from '../authenticate';
import { useLanguage } from '../LanguageContext';

const countries = [
  { code: 'DK', name: 'Denmark', danish_name: 'Danmark' },
  { code: 'GB', name: 'United Kingdom', danish_name: 'Storbritannien' },
  { code: 'US', name: 'United States of America', danish_name: 'USA' },
  { code: 'DE', name: 'Germany', danish_name: 'Tyskland' },
  { code: 'SE', name: 'Sweden', danish_name: 'Sverige' },
  { code: 'NO', name: 'Norway', danish_name: 'Norge' },
  { code: 'AF', name: 'Afghanistan', danish_name: 'Afghanistan' },
  { code: 'AL', name: 'Albania', danish_name: 'Albanien' },
  { code: 'DZ', name: 'Algeria', danish_name: 'Algeriet' },
  { code: 'AD', name: 'Andorra', danish_name: 'Andorra' },
  { code: 'AO', name: 'Angola', danish_name: 'Angola' },
  { code: 'AG', name: 'Antigua and Barbuda', danish_name: 'Antigua og Barbuda' },
  { code: 'AR', name: 'Argentina', danish_name: 'Argentina' },
  { code: 'AM', name: 'Armenia', danish_name: 'Armenien' },
  { code: 'AU', name: 'Australia', danish_name: 'Australien' },
  { code: 'AT', name: 'Austria', danish_name: 'Østrig' },
  { code: 'AZ', name: 'Azerbaijan', danish_name: 'Aserbajdsjan' },
  { code: 'BS', name: 'Bahamas', danish_name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain', danish_name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh', danish_name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados', danish_name: 'Barbados' },
  { code: 'BY', name: 'Belarus', danish_name: 'Hviderusland' },
  { code: 'BE', name: 'Belgium', danish_name: 'Belgien' },
  { code: 'BZ', name: 'Belize', danish_name: 'Belize' },
  { code: 'BJ', name: 'Benin', danish_name: 'Benin' },
  { code: 'BT', name: 'Bhutan', danish_name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia', danish_name: 'Bolivia' },
  { code: 'BA', name: 'Bosnia and Herzegovina', danish_name: 'Bosnien-Hercegovina' },
  { code: 'BW', name: 'Botswana', danish_name: 'Botswana' },
  { code: 'BR', name: 'Brazil', danish_name: 'Brasilien' },
  { code: 'BN', name: 'Brunei', danish_name: 'Brunei' },
  { code: 'BG', name: 'Bulgaria', danish_name: 'Bulgarien' },
  { code: 'BF', name: 'Burkina Faso', danish_name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi', danish_name: 'Burundi' },
  { code: 'CV', name: 'Cabo Verde', danish_name: 'Kap Verde' },
  { code: 'KH', name: 'Cambodia', danish_name: 'Cambodja' },
  { code: 'CM', name: 'Cameroon', danish_name: 'Cameroun' },
  { code: 'CA', name: 'Canada', danish_name: 'Canada' },
  { code: 'CF', name: 'Central African Republic', danish_name: 'Den Centralafrikanske Republik' },
  { code: 'TD', name: 'Chad', danish_name: 'Tchad' },
  { code: 'CL', name: 'Chile', danish_name: 'Chile' },
  { code: 'CN', name: 'China', danish_name: 'Kina' },
  { code: 'CO', name: 'Colombia', danish_name: 'Colombia' },
  { code: 'KM', name: 'Comoros', danish_name: 'Comorerne' },
  { code: 'CG', name: 'Congo (Brazzaville)', danish_name: 'Congo-Brazzaville' },
  { code: 'CD', name: 'Congo (Kinshasa)', danish_name: 'Congo-Kinshasa' },
  { code: 'CR', name: 'Costa Rica', danish_name: 'Costa Rica' },
  { code: 'HR', name: 'Croatia', danish_name: 'Kroatien' },
  { code: 'CU', name: 'Cuba', danish_name: 'Cuba' },
  { code: 'CY', name: 'Cyprus', danish_name: 'Cypern' },
  { code: 'CZ', name: 'Czech Republic', danish_name: 'Tjekkiet' },
  { code: 'DJ', name: 'Djibouti', danish_name: 'Djibouti' },
  { code: 'DM', name: 'Dominica', danish_name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic', danish_name: 'Den Dominikanske Republik' },
  { code: 'EC', name: 'Ecuador', danish_name: 'Ecuador' },
  { code: 'EG', name: 'Egypt', danish_name: 'Egypten' },
  { code: 'SV', name: 'El Salvador', danish_name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea', danish_name: 'Ækvatorialguinea' },
  { code: 'ER', name: 'Eritrea', danish_name: 'Eritrea' },
  { code: 'EE', name: 'Estonia', danish_name: 'Estland' },
  { code: 'ET', name: 'Ethiopia', danish_name: 'Etiopien' },
  { code: 'FJ', name: 'Fiji', danish_name: 'Fiji' },
  { code: 'FI', name: 'Finland', danish_name: 'Finland' },
  { code: 'FR', name: 'France', danish_name: 'Frankrig' },
  { code: 'GA', name: 'Gabon', danish_name: 'Gabon' },
  { code: 'GM', name: 'Gambia', danish_name: 'Gambia' },
  { code: 'GE', name: 'Georgia', danish_name: 'Georgien' },
  { code: 'GH', name: 'Ghana', danish_name: 'Ghana' },
  { code: 'GR', name: 'Greece', danish_name: 'Grækenland' },
  { code: 'GD', name: 'Grenada', danish_name: 'Grenada' },
  { code: 'GT', name: 'Guatemala', danish_name: 'Guatemala' },
  { code: 'GN', name: 'Guinea', danish_name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau', danish_name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana', danish_name: 'Guyana' },
  { code: 'HT', name: 'Haiti', danish_name: 'Haiti' },
  { code: 'HN', name: 'Honduras', danish_name: 'Honduras' },
  { code: 'HU', name: 'Hungary', danish_name: 'Ungarn' },
  { code: 'IS', name: 'Iceland', danish_name: 'Island' },
  { code: 'IN', name: 'India', danish_name: 'Indien' },
  { code: 'ID', name: 'Indonesia', danish_name: 'Indonesien' },
  { code: 'IR', name: 'Iran', danish_name: 'Iran' },
  { code: 'IQ', name: 'Iraq', danish_name: 'Irak' },
  { code: 'IE', name: 'Ireland', danish_name: 'Irland' },
  { code: 'IL', name: 'Israel', danish_name: 'Israel' },
  { code: 'IT', name: 'Italy', danish_name: 'Italien' },
  { code: 'CI', name: 'Ivory Coast', danish_name: 'Elfenbenskysten' },
  { code: 'JM', name: 'Jamaica', danish_name: 'Jamaica' },
  { code: 'JP', name: 'Japan', danish_name: 'Japan' },
  { code: 'JO', name: 'Jordan', danish_name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan', danish_name: 'Kasakhstan' },
  { code: 'KE', name: 'Kenya', danish_name: 'Kenya' },
  { code: 'KI', name: 'Kiribati', danish_name: 'Kiribati' },
  { code: 'KW', name: 'Kuwait', danish_name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan', danish_name: 'Kirgisistan' },
  { code: 'LA', name: 'Laos', danish_name: 'Laos' },
  { code: 'LV', name: 'Latvia', danish_name: 'Letland' },
  { code: 'LB', name: 'Lebanon', danish_name: 'Libanon' },
  { code: 'LS', name: 'Lesotho', danish_name: 'Lesotho' },
  { code: 'LR', name: 'Liberia', danish_name: 'Liberia' },
  { code: 'LY', name: 'Libya', danish_name: 'Libyen' },
  { code: 'LI', name: 'Liechtenstein', danish_name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania', danish_name: 'Litauen' },
  { code: 'LU', name: 'Luxembourg', danish_name: 'Luxembourg' },
  { code: 'MK', name: 'North Macedonia', danish_name: 'Nordmakedonien' },
  { code: 'MG', name: 'Madagascar', danish_name: 'Madagaskar' },
  { code: 'MW', name: 'Malawi', danish_name: 'Malawi' },
  { code: 'MY', name: 'Malaysia', danish_name: 'Malaysia' },
  { code: 'MV', name: 'Maldives', danish_name: 'Maldiverne' },
  { code: 'ML', name: 'Mali', danish_name: 'Mali' },
  { code: 'MT', name: 'Malta', danish_name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands', danish_name: 'Marshalløerne' },
  { code: 'MR', name: 'Mauritania', danish_name: 'Mauretanien' },
  { code: 'MU', name: 'Mauritius', danish_name: 'Mauritius' },
  { code: 'MX', name: 'Mexico', danish_name: 'Mexico' },
  { code: 'FM', name: 'Micronesia', danish_name: 'Mikronesien' },
  { code: 'MD', name: 'Moldova', danish_name: 'Moldova' },
  { code: 'MC', name: 'Monaco', danish_name: 'Monaco' },
  { code: 'MN', name: 'Mongolia', danish_name: 'Mongoliet' },
  { code: 'ME', name: 'Montenegro', danish_name: 'Montenegro' },
  { code: 'MA', name: 'Morocco', danish_name: 'Marokko' },
  { code: 'MZ', name: 'Mozambique', danish_name: 'Mozambique' },
  { code: 'MM', name: 'Myanmar', danish_name: 'Myanmar (Burma)' },
  { code: 'NA', name: 'Namibia', danish_name: 'Namibia' },
  { code: 'NR', name: 'Nauru', danish_name: 'Nauru' },
  { code: 'NP', name: 'Nepal', danish_name: 'Nepal' },
  { code: 'NL', name: 'Netherlands', danish_name: 'Holland' },
  { code: 'NZ', name: 'New Zealand', danish_name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua', danish_name: 'Nicaragua' },
  { code: 'NE', name: 'Niger', danish_name: 'Niger' },
  { code: 'NG', name: 'Nigeria', danish_name: 'Nigeria' },
  { code: 'KP', name: 'North Korea', danish_name: 'Nordkorea' },
  { code: 'OM', name: 'Oman', danish_name: 'Oman' },
  { code: 'PK', name: 'Pakistan', danish_name: 'Pakistan' },
  { code: 'PW', name: 'Palau', danish_name: 'Palau' },
  { code: 'PS', name: 'Palestinian Territories', danish_name: 'Palæstina' },
  { code: 'PA', name: 'Panama', danish_name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea', danish_name: 'Papua Ny Guinea' },
  { code: 'PY', name: 'Paraguay', danish_name: 'Paraguay' },
  { code: 'PE', name: 'Peru', danish_name: 'Peru' },
  { code: 'PH', name: 'Philippines', danish_name: 'Filippinerne' },
  { code: 'PL', name: 'Poland', danish_name: 'Polen' },
  { code: 'PT', name: 'Portugal', danish_name: 'Portugal' },
  { code: 'QA', name: 'Qatar', danish_name: 'Qatar' },
  { code: 'RO', name: 'Romania', danish_name: 'Rumænien' },
  { code: 'RU', name: 'Russia', danish_name: 'Rusland' },
  { code: 'RW', name: 'Rwanda', danish_name: 'Rwanda' },
  { code: 'KN', name: 'Saint Kitts and Nevis', danish_name: 'Saint Kitts og Nevis' },
  { code: 'LC', name: 'Saint Lucia', danish_name: 'Saint Lucia' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines', danish_name: 'Saint Vincent og Grenadinerne' },
  { code: 'WS', name: 'Samoa', danish_name: 'Samoa' },
  { code: 'SM', name: 'San Marino', danish_name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe', danish_name: 'São Tomé og Príncipe' },
  { code: 'SA', name: 'Saudi Arabia', danish_name: 'Saudi-Arabien' },
  { code: 'SN', name: 'Senegal', danish_name: 'Senegal' },
  { code: 'RS', name: 'Serbia', danish_name: 'Serbien' },
  { code: 'SC', name: 'Seychelles', danish_name: 'Seychellerne' },
  { code: 'SL', name: 'Sierra Leone', danish_name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapore', danish_name: 'Singapore' },
  { code: 'SK', name: 'Slovakia', danish_name: 'Slovakiet' },
  { code: 'SI', name: 'Slovenia', danish_name: 'Slovenien' },
  { code: 'SB', name: 'Solomon Islands', danish_name: 'Salomonøerne' },
  { code: 'SO', name: 'Somalia', danish_name: 'Somalia' },
  { code: 'ZA', name: 'South Africa', danish_name: 'Sydafrika' },
  { code: 'KR', name: 'South Korea', danish_name: 'Sydkorea' },
  { code: 'SS', name: 'South Sudan', danish_name: 'Sydsudan' },
  { code: 'ES', name: 'Spain', danish_name: 'Spanien' },
  { code: 'LK', name: 'Sri Lanka', danish_name: 'Sri Lanka' },
  { code: 'SD', name: 'Sudan', danish_name: 'Sudan' },
  { code: 'SR', name: 'Suriname', danish_name: 'Surinam' },
  { code: 'SZ', name: 'Eswatini', danish_name: 'Eswatini' },
  { code: 'CH', name: 'Switzerland', danish_name: 'Schweiz' },
  { code: 'SY', name: 'Syria', danish_name: 'Syrien' },
  { code: 'TW', name: 'Taiwan', danish_name: 'Taiwan' },
  { code: 'TJ', name: 'Tajikistan', danish_name: 'Tadsjikistan' },
  { code: 'TZ', name: 'Tanzania', danish_name: 'Tanzania' },
  { code: 'TH', name: 'Thailand', danish_name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste', danish_name: 'Østtimor' },
  { code: 'TG', name: 'Togo', danish_name: 'Togo' },
  { code: 'TO', name: 'Tonga', danish_name: 'Tonga' },
  { code: 'TT', name: 'Trinidad and Tobago', danish_name: 'Trinidad og Tobago' },
  { code: 'TN', name: 'Tunisia', danish_name: 'Tunesien' },
  { code: 'TR', name: 'Turkey', danish_name: 'Tyrkiet' },
  { code: 'TM', name: 'Turkmenistan', danish_name: 'Turkmenistan' },
  { code: 'TV', name: 'Tuvalu', danish_name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda', danish_name: 'Uganda' },
  { code: 'UA', name: 'Ukraine', danish_name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates', danish_name: 'Forenede Arabiske Emirater' },
  { code: 'UY', name: 'Uruguay', danish_name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan', danish_name: 'Usbekistan' },
  { code: 'VU', name: 'Vanuatu', danish_name: 'Vanuatu' },
  { code: 'VA', name: 'Vatican City', danish_name: 'Vatikanstaten' },
  { code: 'VE', name: 'Venezuela', danish_name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam', danish_name: 'Vietnam' },
  { code: 'YE', name: 'Yemen', danish_name: 'Yemen' },
  { code: 'ZM', name: 'Zambia', danish_name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe', danish_name: 'Zimbabwe' }
];

  const apiLink = process.env.REACT_APP_API_LINK

  const Register = () => {
  const { translations, language } = useLanguage();
  useAuthentication();

    const [registerFailed, setRegisterFailed] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [newsletter, setNewsletter] = useState(false);
    const [message, setMessage] = useState('');

    const [formData, setFormData] = useState({
      email: '',
      password: '',
      confirmPassword: '',
      country: '',
      uid: '',
      newsletter: false,
    });

    const navigate = useNavigate();

    const closeMessage = () => {
      setRegisterFailed(false);
    };

    const handleAcceptTermsChange = (e) => {
      setAcceptTerms(e.target.checked);
    };

    const handleNewsletterChange = (e) => {
      const isChecked = e.target.checked;
      setNewsletter(isChecked);
      setFormData((prevFormData) => ({
        ...prevFormData,
        newsletter: isChecked,
      }));
    }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };
  
    const updateUid = async (userId, email) => {
      try {
        const response = await fetch(apiLink + 'updateUid', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId, email: email }),
        });
        if (!response.ok) {
          console.log('User configuration has partially failed... - Retrying later...');
        }
      } catch (error) {
        console.error(error);
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
          
      if (!acceptTerms) {
        setRegisterFailed(true);
        setMessage(translations.accept_tos);
        return;
      }
    
      if (formData.password !== formData.confirmPassword) {
        setRegisterFailed(true);
        setMessage(translations.error_occurred, '(passwords-do-not-match)');
        return;
      }
    
      const { email, password } = formData;
      let user = null;
    
      try {
        // Create Firebase user first
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        user = userCredential.user;
    
        // Proceed with API reg
        const response = await fetch(apiLink + 'register', {
          method: 'POST',
          body: JSON.stringify(formData),
        });
    
        const data = await response.json();
    
        if (!response.ok) {
          // If API reg fails, del Firebase user
          await user.delete();
          setRegisterFailed(true);
          setMessage(`${translations.reg_failed} ${data.error}`);
          return;
        }
    
        // Send verification email & update uid
        await sendEmailVerification(user);
        await updateUid(user.uid, email);
        navigate('/plans');
      } catch (error) {
        if (user) {
          await user.delete();
        }
        setRegisterFailed(true);
        setMessage(`${translations.error_occurred} (${error.code})`);
      }
    };
  
    return (
      <>
      <div>
      <Helmet>
        <title>{translations.create_account} - Stenz Digital</title>
      </Helmet>
    </div>
      <div className="flex flex-col justify-center items-center min-h-screen p-4">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl">
        {registerFailed ? (
          <Message message={message} onClose={closeMessage} />
        ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-gray-900 rounded-lg shadow-lg p-8 text-white"
        >
          <h2 className="text-2xl sm:text-3xl font-semibold mb-4 sm:mb-6">{translations.create_account}</h2>
          <div className="mb-4">
            <label htmlFor="email" className="text-sm font-medium mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder={translations.enter_email}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="text-sm font-medium mb-2">
            {translations.password}
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder={translations.enter_password}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="confirmPassword" className="text-sm font-medium mb-2">
            {translations.confirm_password}
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder={translations.confirm_enter_password}
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="country" className="text-sm font-medium mb-2">
              {translations.country}
            </label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              required
            >
              <option value="">{translations.select_country}</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {language === 'da' ? country.danish_name : country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
                <label htmlFor="acceptTerms" className="flex items-center">
                  <input
                    type="checkbox"
                    id="acceptTerms"
                    name="acceptTerms"
                    checked={acceptTerms}
                    onChange={handleAcceptTermsChange}
                    className="mr-2"
                  />
                  <span className="text-sm font-medium">
                    {translations.i_accept} 
                    <Link 
                    to='/legal/tos'
                    target='_blank' rel='noopener noreferrer'
                    >                
                      <span className='font-bold underline hover:text-blue-500 text-sm ml-1'>{translations.tos}</span>
                    </Link>
                  </span>
                </label>
          </div>
          <div className="mb-4">
                <label htmlFor="newsletter" className="flex items-center">
                  <input
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    checked={newsletter}
                    onChange={handleNewsletterChange}
                    className="mr-2"
                  />
                  <span className="text-sm font-medium">
                  {translations.consent_newsletter}
                  </span>
                </label>
          </div>
          <div className="flex items-center justify-center p-2 pb-4">
            <button
              type="submit"
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
            >
              {translations.create_account}
            </button>
          </div>
          <div>
            <p>{translations.have_account}</p>
            <NavLink to='/login'>
              <p className='font-bold underline hover:text-blue-500'>{translations.login}</p>
            </NavLink>
          </div>
        </form>
        )}
        </div>
      </div>
      </>
    );
  };
  
export default Register;