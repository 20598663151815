import React, { createContext, useContext, useState } from 'react';
import en from './translations/en.json'
import da from './translations/da.json';

// Create the Language Context
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');
    const [translations, setTranslations] = useState(en);
  
    const changeLanguage = (lang) => {
      setLanguage(lang);
      setTranslations(lang === 'da' ? da : en);
    };
  
    return (
      <LanguageContext.Provider value={{ language, translations, changeLanguage }}>
        {children}
      </LanguageContext.Provider>
    );
  };
  
  // Custom hook to use the Language Context
  export const useLanguage = () => {
    return useContext(LanguageContext);
  };
