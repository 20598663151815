import { signOut } from "firebase/auth";
import auth from "../firebase";
import { useNavigate } from "react-router-dom";
import Message from "./Message";
import { useState, useEffect } from "react";

const Logout = () =>{
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [logoutFailed, setLogoutFailed] = useState(false);

    useEffect(() => {
        handleLogout();
    });

    const closeMessage = () => {
        setLogoutFailed(false);
        navigate('/home');
    };    

    const handleLogout = async () =>{
        try {
            await signOut(auth);
            navigate('/');
        } catch(err) {
            console.error('Logout error:', err);
            setLogoutFailed(true);
            setMessage('Failed to log you out. Please try again later.');
        }
    };

    return (
        <>
            {logoutFailed && <Message message={message} onClose={closeMessage}/>}
        </>
    );
};

export default Logout;
