import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import Message from './Message';
import MediaModal from './Media';

const apiLink = process.env.REACT_APP_API_LINK;
const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK

const ViewSingleProduct = () => {
  const user = useAuthentication();

  const { sn, sid, pid } = useParams();
  const [loading, setLoading] = useState(true);
  const [siteUrl, setSiteUrl] = useState('');
  const [status, setStatus] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [showUpdateProductModal, setShowUpdateProductModal] = useState(false);
  const [currency, setCurrency] = useState('');
  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productSizes, setProductSizes] = useState('');
  const [productImage, setProductImage] = useState('');
  const [productImageName, setProductImageName] = useState('');
  const [product, setProduct] = useState('');
  const [productUpdated, setProductUpdated] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [customDomain, setCustomDomain] = useState('');
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        const template = siteFound?.template;
        const url = siteFound?.customDomain || siteFound?.domain;

        setSiteUrl(url);
          
        if (!siteFound || !url || !template || template !== 'e-commerce') {
          navigate(`/sites/${sn}/${sid}`);
        }
        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomain(siteFound?.customDomain);
        }
        if (siteFound && siteFound?.currency)
        {
          setCurrency(siteFound?.currency);
        }
      } catch (error) {
        console.error(error);
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    const fetchData = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        
        if(!siteFound?.hosted)
        {
          navigate(`/sites/${sn}/${sid}`);
        }

        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if (data.status && sn === siteFound.name) {
          setStatus(data.status);
          await fetchUserSites();
          setLoading(false);
        } else {
          navigate(`/sites/${sn}/${sid}`);
        }
      } catch (error) {
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    // Fetch site data initially
    fetchData();

  }, [sid, sn, navigate, user]);

  const fetchProducts = async () => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;

      const response = await fetch(apiLink + 'getProducts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId }),
      });

      if (!response.ok) {
        setLoading(false);
        return;
      }

      const data = await response.json();
      const productFound = data.products.find(product => product.productId === pid);
      console.log(productFound);
      setProduct(productFound);
      setProductName(productFound?.name);
      setProductPrice(productFound?.price);
      setProductDescription(productFound?.description)
      setProductSizes(productFound?.sizes || null);
      setProductImage(productFound?.image);

      // Check if the product is archived
      if (productFound && productFound.archived) {
        setIsArchived(true);
      } else {
        setIsArchived(false);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (!user.emailVerified) {
        setMessage('Please verify your email address to unlock all features.');
        setShowMessage(true);
      } else {
        fetchProducts();
      }
    }
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleCloseMessage = () => {
    setShowMessage(false);
    if(isDeleted)
    {
      navigate(`/sites/${sn}/${sid}/dashboard/products`);
    }
    if (productUpdated) {
      setProductUpdated(false);
      return;
    }
    if (!user.emailVerified) {
      navigate('/home');
    }
  };

  const handleUpdateProduct = async () => {
    try {

      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;

      let pSizes
      if(productSizes)
      {
        pSizes = productSizes.split(',').map(size => size.trim());
      }
      else
      {
        pSizes = null;
      }

      const response = await fetch(apiLink + 'updateProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId, productId: product.productId, productName, productPrice, productDescription, productSizes: pSizes, productImage }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(`Failed to update your product. Please try again later. (${data.error})`);
        setShowUpdateProductModal(false);
        setShowMessage(true);
        return;
      }

      setMessage(`Your product ${productName} was updated successfully.`);
      setProductUpdated(true);
      setShowMessage(true);
      setShowUpdateProductModal(false);
      fetchProducts(user);
    } catch (error) {
      setMessage('Failed to update your product. Please try again later. (product/update-failed)');
      setShowUpdateProductModal(false);
      setShowMessage(true);
    }
  };

  const handleArchiveProduct = async () => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;
  
      const response = await fetch(apiLink + (!isArchived ? 'archiveProduct' : 'removeFromArchive'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId, productId: product.productId }),
      });
  
      const data = await response.json();
  
      if (data.error) {
        setMessage(`Failed to ${!isArchived ? 'archive' : 'remove from archive'} your product. Please try again later. (${data.error})`);
        setShowUpdateProductModal(false);
        setShowMessage(true);
        return;
      }
  
      setMessage(`Your product ${productName} was ${!isArchived ? 'archived' : 'removed from archive'} successfully.`);
      setProductUpdated(true);
      setShowMessage(true);
      setShowUpdateProductModal(false);
      fetchProducts(user);
    } catch (error) {
      setMessage(`Failed to ${!isArchived ? 'archive' : 'remove from archive'} your product. Please try again later. (product/${!isArchived ? 'archive' : 'remove-from-archive'}-failed)`);
      setShowMessage(true);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      const siteId = siteFound?.siteId;
  
      const response = await fetch(apiLink + 'deleteProduct', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: siteId, productId: product.productId }),
      });
  
      const data = await response.json();
  
      if (data.error) {
        setMessage(`Failed to delete your product. Please try again later. (${data.error})`);
        setShowDeleteConfirmModal(false);
        setShowMessage(true);
        return;
      }
  
      setMessage(`Your product ${productName} was deleted successfully.`);
      setIsDeleted(true);
      setShowMessage(true);
      setShowDeleteConfirmModal(false);
    } catch (error) {
      setMessage('Failed to delete your product. Please try again later. (product/delete-failed)');
      setShowMessage(true);
    }
  };

  const handleOpenModal = () => {
    setIsMediaModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsMediaModalOpen(false);
  };

  const handleSelectMedia = (mediaUrl, mediaName) => {
    setProductImage(mediaUrl); 
    setProductImageName(mediaName);
    setIsMediaModalOpen(false);
  };

  const isUpdateProductButtonDisabled = productName === '' || productPrice === '' || productDescription === '' || productImage === '';

  return (
    <div className="flex flex-col justify-center items-center h-screen text-white">
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage && (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      )}
      {isMediaModalOpen && (
        <MediaModal
          isOpen={isMediaModalOpen}
          onClose={handleCloseModal}
          onSelectMedia={handleSelectMedia}
        />
      )}
      {!showMessage && (
        <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white w-full md:w-3/4 lg:w-1/2 xl:w-2/3 overflow-y-auto max-h-screen overflow-x-hidden">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Product Settings</header>
        {loading ? (
          <div className="mb-4">
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
          </div>
        ) : (
          <>
            {product && (
              <>
                <div className="mb-4">
                  <p className="text-xl text-gray-400">{product.name}</p>
                  <p className="text-lg text-gray-400">{currency}{product.price}</p>
                </div>
                <div className='flex justify-center items-center'>
                  <img src={product.image} alt={product.name} className="mb-2 w-48 h-48 object-cover rounded mx-auto" />
                </div>
              
                <div className="grid grid-cols-2 gap-4">
                <div className="p-4 flex">
                  <div className={`bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={() => setShowUpdateProductModal(true)}><span className="block sm:block md:inline">Edit</span> <span className="block sm:block md:inline">Product</span></div>
                </div>
                <div className="p-4 flex">
                  <div className={`bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 hover:bg-gray-700 ${status === 'Published' ? 'cursor-pointer' : 'cursor-not-allowed'}`}><Link className={status === 'Published' ? 'cursor-pointer' : 'cursor-not-allowed'} to={`${status === 'Published' ? `${siteUrl}/preview?p=${product.name.replace(/ /g, '-')}` : ''}`} target={status === 'Published' ? '_blank' : null}>View Product</Link></div>
                </div>
                <div className="p-4 flex">
                  <div className={`bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 hover:bg-gray-700 cursor-pointer`} onClick={() => handleArchiveProduct()}>{!isArchived ? 'Archive Product' : 'Remove from Archive'}</div>
                </div>
                <div className="p-4 flex">
                  <div className={`bg-gray-600 p-4 flex-grow rounded-lg text-red-400 hover:bg-gray-700 cursor-pointer`} onClick={() => setShowDeleteConfirmModal(true)}>Delete Product</div>
                </div>
              </div>
            </>
            )} 
          </>
        )}
        <div className="pt-2">
          <Link to={`/sites/${sn}/${sid}/dashboard/products`}>
            <p className="font-bold underline hover:text-blue-500">Back</p>
          </Link>
        </div>
      </div>
      )}

      {showUpdateProductModal && (
        <div className="fixed inset-0 z-2 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Update Product</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="productName" className="block text-sm font-medium mb-2">Product Name*</label>
                <input
                  type="text"
                  id="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product name"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productPrice" className="block text-sm font-medium mb-2">Product Price*</label>
                <input
                  type="number"
                  id="productPrice"
                  value={productPrice}
                  onChange={(e) => setProductPrice(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product price"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="productDescription" className="block text-sm font-medium mb-2">Product Description*</label>
                <textarea
                  type="text"
                  id="productDescription"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300 resize-none"
                  placeholder="Enter product description"
                  rows={4}
                  required
                />
              </div>
              {productSizes && (
                <div className="mb-4">
                <label htmlFor="productSizes" className="block text-sm font-medium mb-2">Product Sizes</label>
                <input
                  type="text"
                  id="productSizes"
                  value={productSizes}
                  onChange={(e) => setProductSizes(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter product sizes (e.g., S, M, L)"
                />
              </div>
              )}
              <div className="mb-4">
                <label htmlFor="productImage" className="block text-sm font-medium mb-2">Product Image*</label>
                <Link to={productImage} target='_blank' className='font-bold underline hover:text-blue-500'>{productImageName}</Link>
              </div>
              <div className="mb-4">
                <div className='cursor-pointer mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300' onClick={handleOpenModal}>
                  {!productImage ? 'Choose Image' : 'Change Image'}
                </div>
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowUpdateProductModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="button" onClick={handleUpdateProduct} disabled={isUpdateProductButtonDisabled} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Update Product</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showDeleteConfirmModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Delete Product</h2>
            <p>Are you sure you want to delete the product <span className='font-bold'>{product.name}</span>?</p>
            <p className='bg-red-500 text-lg text-center mt-4'>This action is irreversible </p>
            <div className="flex justify-end mt-4">
              <button type="button" onClick={() => setShowDeleteConfirmModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
              <button type="button" onClick={handleDeleteProduct} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSingleProduct;
