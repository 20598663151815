import { useEffect } from 'react';
import { useLanguage } from './LanguageContext';

const AutoLang = () => {
  const { changeLanguage } = useLanguage();

  const fetchCountry = async () => {
    try {
      const apiLink = process.env.REACT_APP_API_LINK;
      const response = await fetch(apiLink + 'getCountry', {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      });

      if (response.ok) {
        const { country } = await response.json();
        if (country === 'DK') {
          changeLanguage('da');
        } else {
          // Default to English
          changeLanguage('en');
        }
      } else {
        console.error('Failed to fetch country');
      }
    } catch (error) {
      console.error('Error fetching country:', error);
    }
  };

  useEffect(() => {
    fetchCountry();
    //eslint-disable-next-line
  }, []);

  return null; // Nothing to render
};

export default AutoLang;
