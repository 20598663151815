import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaGlobe, FaUser, FaSignOutAlt, FaCreditCard } from 'react-icons/fa';
import './Header.css';
import useAuthentication from '../authenticate2';

const MobileHeader = () => {
  const user = useAuthentication();

  return (
    (user &&
    <header className="bg-gray-900 md:hidden fixed top-0 left-0 right-0 z-10">
      <nav className="flex justify-between px-4 py-4">
        {/*
        <NavLink to="/home" className="text-white flex flex-col items-center justify-center p-2">
          <FaHome className="w-6 h-6" />
          <span className="text-xs">Home</span>
        </NavLink>
        */}
        <NavLink to="/sites" className="text-white flex flex-col items-center justify-center p-2">
          <FaGlobe className="w-6 h-6" />
        </NavLink>
        {/*
        <NavLink to="/email" className="text-white flex flex-col items-center justify-center p-2">
          <FaEnvelope className="w-6 h-6" />
        </NavLink>
        <NavLink to="/ads" className="text-white flex flex-col items-center justify-center p-2">
          <FaRocket className="w-6 h-6" />
        </NavLink>
        */}
        <NavLink to="/billing" className="text-white flex flex-col items-center justify-center p-2">
          <FaCreditCard className="w-6 h-6" />
        </NavLink>
        <NavLink to="/account" className="text-white flex flex-col items-center justify-center p-2">
          <FaUser className="w-6 h-6" />
        </NavLink>
        <NavLink to="/logout" className="text-white flex flex-col items-center justify-center p-2">
          <FaSignOutAlt className="w-6 h-6" />
        </NavLink>
      </nav>
    </header>
    )
  );
};

export default MobileHeader;
