import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink, useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import auth from '../firebase';
import useAuthentication from '../authenticate2';

const ResetPassword = () => {
const user = useAuthentication();

  const [formData, setFormData] = useState({
    email: '',
  });

  const [emailSent, setEmailSent] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sign in with email and password
      await sendPasswordResetEmail(auth, formData.email);

    } catch (error) {
        //Nothing happens
    }
    setEmailSent(true);
  };

  const msg = 'If the email is correct, an email with a link to reset your password has been sent.';

  (emailSent && navigate(`/e/?msg=${encodeURIComponent(msg)}`));

  return (
    <>
      <div>
        <Helmet>
          <title>Reset Password - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen">
          <form
            onSubmit={handleSubmit}
            className="bg-gray-900 rounded-lg shadow-lg p-8 text-white"
          >
            <h2 className="text-3xl font-semibold mb-6">Reset Password</h2>
            <div className="mb-4">
              <label htmlFor="email" className="text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              >
                Reset
              </button>
            </div>
            {!user && 
            (<div className='pt-2'>
                <p>Or</p>
                <NavLink to='/login'>
                  <p className='font-bold underline hover:text-blue-500'>Login</p>
                </NavLink>
              </div>)}
          </form>
      </div>
    </>
  );
};

export default ResetPassword;
