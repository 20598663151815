import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import useAuthentication from '../../authenticate';

const ProductPreview = ({
  title,
  buttonLeftTitle,
  buttonRightTitle,
  titleFontSize,
  priceColor,
  backgroundColor,
  textColor,
  productFontSize,
  productTextColor,
  descriptionFontSize,
  priceFontSize,
  buttonBgColor,
  buttonTextColor,
  sizeBgColor,
  sizeTextColor,
}) => {

const user = useAuthentication();
const { sid } = useParams();
const [currency, setCurrency] = useState('DKK ');

useEffect(() => {
  const getDetails = async () => {
    if (!user || !user.user || !user.user.sites) {
      return;
    }
  
    const sites = user.user.sites;
    const siteFound = sites.find(site => site.siteId === sid);
    const currency = siteFound?.currency;
    if(currency)
    {
      setCurrency(currency);
    }
  }
  getDetails();  
}, [user, sid])


const productDetails = {
  name: 'Product',
  price: '299.99',
  currency: 'DKK ', 
  image: 'https://stenzdigital.com/img/stenzdigitallogo.png', 
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ipsum nunc, pharetra ac viverra ut, sollicitudin ornare turpis. Vivamus sed nunc a eros dignissim rutrum. Suspendisse quis varius dolor, nec vehicula libero. Morbi vel pulvinar diam, non maximus nisl. Vivamus non ante et enim interdum varius.',
  sizes: ['XS', 'S', 'M', 'L', 'XL']
}

return (
  <>
    <div className={`${textColor} ${titleFontSize} text-center p-2 py-4`}>
      <h2>{title}</h2>
    </div>
    <div className={`flex flex-col sm:flex-row justify-between items-start m-auto max-w-screen-xl p-8 sm:p-20 shadow-lg ${backgroundColor}`}>
      <img src={productDetails.image} alt={productDetails.name} className="mx-auto sm:mx-0 sm:mr-12 mb-8 sm:mb-0 max-w-full sm:max-w-none" style={{ maxWidth: '300px' }} />
      <div className="mx-auto sm:ml-8 flex-grow">
        <h3 className={`${productFontSize} mb-4 ${productTextColor}`}>{productDetails.name}</h3>
        <p className={`${descriptionFontSize} mb-4`}>{productDetails.description}</p>
        <p className={`${priceFontSize} font-bold mb-4 ${priceColor}`}>{currency}{productDetails.price}</p>
        <ul className="flex flex-wrap mb-4">
          {productDetails.sizes.map((size) => (
            <li key={size}>
              <button className={`${sizeBgColor} ${sizeTextColor} py-2 px-4 rounded-full font-bold uppercase transition-all mb-2 mr-2 sm:mr-4`}>
                {size}
              </button>
            </li>
          ))}
        </ul>
        <div className="product-buttons flex flex-col sm:flex-row text-center">
          <Link className={`py-2 px-4 rounded-full font-bold uppercase transition-all ${buttonBgColor} ${buttonTextColor} mb-2 sm:mb-0 sm:mr-2`}>
            {buttonLeftTitle}
          </Link>
          <Link className={`py-2 px-4 rounded-full font-bold uppercase transition-all ${buttonBgColor} ${buttonTextColor}`}>
            {buttonRightTitle}
          </Link>
        </div>
      </div>
    </div>
  </>
);
};

export default ProductPreview;
