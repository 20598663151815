import React from 'react';
import { Helmet } from 'react-helmet';

const TOS = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Terms of Service - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <main className="text-center max-w-4xl w-full">
          <section className="mb-8 mt-24 xl:mt-12">
            <h1 className="text-4xl font-bold text-black">Terms of Service</h1>
            <p className="text-gray-700 mt-2">Effective Date: 26/07/2024</p>
          </section>
          <section className="bg-white p-6 rounded-lg shadow-md max-h-[calc(100vh-160px)] overflow-y-auto text-left">
            <h2 className="text-2xl font-semibold">Introduction</h2>
            <p className="mt-2">
              Welcome to Stenz Digital. These Terms of Service govern your use of our website, accessible at
              <a href="https://stenzdigital.app" className="text-blue-600"> https://stenzdigital.app</a>.
              By creating an account, accessing or using our site, you agree to be bound by these terms.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Acceptance of Terms</h2>
            <p className="mt-2">
              By using our website, you agree to comply with these Terms of Service and all applicable laws and
              regulations. If you do not agree with any part of these terms, you should not use our services.
            </p>

            <h2 className="text-2xl font-semibold mt-4">User Responsibilities</h2>
            <p className="mt-2">
              You are responsible for:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>Account Security:</strong> Maintaining the confidentiality of your account information and
                passwords.
              </li>
              <li>
                <strong>Compliance:</strong> Ensuring that your use of our services complies with all applicable
                laws and regulations.
              </li>
              <li>
                <strong>Prohibited Conduct:</strong> Not engaging in any activity that is harmful, fraudulent, or
                otherwise inappropriate.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">Intellectual Property</h2>
            <p className="mt-2">
              All content and materials provided through our website are the intellectual property of Stenz Digital
              or our licensors. You may not use, reproduce, or distribute any content without our explicit permission.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Subscription and License</h2>
            <p className="mt-2">
              We offer paid services through a subscription model. By subscribing to our services, you agree to the
              following terms:
            </p>
            <ul className="list-disc ml-6 mt-2">
              <li>
                <strong>Subscription Plans:</strong> We offer various subscription plans with different features and
                pricing. Detailed information about the available plans and pricing is provided on our website.
              </li>
              <li>
                <strong>Payment Terms:</strong> Subscription fees are billed in advance on a recurring basis (monthly
                or annually) as per the plan you choose. Payments are processed through Stripe and are generally
                non-refundable, except as required by EU law.
              </li>
              <li>
                <strong>Automatic Renewal:</strong> Subscriptions automatically renew at the end of each billing cycle
                unless you cancel your subscription before the renewal date. You will be charged for the next billing
                period unless you cancel.
              </li>
              <li>
                <strong>Cancellation:</strong> You may cancel your subscription at any time through your account
                settings or by contacting our support team. Cancellation will take effect at the end of the current
                billing cycle. If you cancel, you will not be charged for subsequent periods.
              </li>
              <li>
                <strong>Refunds:</strong> In accordance with EU laws, you have the right to a refund within 14 days of
                the initial purchase, provided that you have not used the service. After this period, all subscription
                fees are non-refundable.
              </li>
              <li>
                <strong>License:</strong> Your subscription grants you a limited, non-exclusive, non-transferable
                license to access and use our services for the duration of the subscription. You may not transfer,
                share, or resell your subscription.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mt-4">Limitation of Liability</h2>
            <p className="mt-2">
              To the maximum extent permitted by law, Stenz Digital shall not be liable for any indirect, incidental,
              special, or consequential damages arising out of or related to your use of our services.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Termination</h2>
            <p className="mt-2">
              We reserve the right to suspend or terminate your access to our services at any time, with or without
              cause, if we believe you have violated these Terms of Service.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Governing Law</h2>
            <p className="mt-2">
              These Terms of Service are governed by and construed in accordance with the laws of Denmark, without
              regard to its conflict of law principles.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Changes to Terms</h2>
            <p className="mt-2">
              We may update these Terms of Service from time to time. Any changes will be posted on our website, and
              your continued use of our services constitutes acceptance of the revised terms.
            </p>

            <h2 className="text-2xl font-semibold mt-4">Contact Us</h2>
            <p className="mt-2">
              If you have any questions about these Terms of Service, please contact us at
              <a href="mailto:contact@stenzdigital.com" className="text-blue-600"> contact@stenzdigital.com</a>.
            </p>
          </section>
        </main>
      </div>
    </>
  );
};

export default TOS;
