import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import auth from './firebase';

const apiLink = process.env.REACT_APP_API_LINK;

const useUpdateAuth = () => {

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {        
        // Fetch additional user information from backend
        const response = await fetch(apiLink + 'getUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: fbUser.uid }),
        });

        if (!response.ok) {
          // Fetch additional user information from backend
          const response = await fetch(apiLink + 'updateUid', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({userId: fbUser.uid, email: fbUser.email })
          });

          if (!response.ok) {
            console.log('Not Authorized!');
            return;
          }
          return;
        }

        const data = await response.json();

        if(!data.user.uid)
        {
            // Call /updateUid endpoint
            await fetch(apiLink + 'updateUid', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ userId: fbUser.uid, email: fbUser.email }),
            });
        }

        // Check if the emails match
        if (fbUser.email !== data.user.email) {

          // Call /updateEmail endpoint to update email
          await fetch(apiLink + 'updateEmail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: fbUser.uid, email: fbUser.email }),
          });

          if (response.ok) {
            console.log('Updated email!');
            return;
          }
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
  }, []);
};

export default useUpdateAuth;
