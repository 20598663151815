import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const EntPlan = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Enterprise Plan - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex flex-col justify-center items-center min-h-screen text-white bg-gray-800">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl p-4">
          <h1 className="text-4xl font-semibold py-6 mt-10 lg:mt-0 mb-6">Enterprise Plan</h1>
          <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
            <p className="text-gray-300 mb-6">
              The Enterprise Plan is tailored for large organizations or businesses with advanced requirements.
            </p>
            <div className="bg-gray-700 p-6 rounded-lg mb-6">
              <h2 className="text-2xl font-semibold text-gray-100 mb-4">Features</h2>
              <ul className="list-disc list-inside text-gray-200">
                <li>Custom Solutions</li>
                <li>Unlimited Sites</li>
                <li>Dedicated Hosting</li>
                <li>Dedicated Account Manager</li>
                <li>Advanced Analytics</li>
                <li>Enhanced Security</li>
                <li>Custom Integrations</li>
                <li>Scalable</li>
                <li>Everything in Pro</li>
              </ul>
            </div>
            <div className="text-center mb-6">
              <p className="text-2xl font-bold text-gray-100 mb-2">Pricing</p>
              <p className="text-gray-300 text-xl">Custom Pricing</p>
            </div>
            <div className="flex justify-center mb-12">
              <Link to={'/contact'} className="text-2xl bg-yellow-500 hover:bg-yellow-600 text-white font-bold px-8 py-3 rounded-lg transition transform hover:underline hover:border-2 border-white hover:scale-105">
                Contact Sales
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EntPlan;
