import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';

const CustomerVerification = () => {
useAuthentication();

  const [formData, setFormData] = useState({
    address: '',
    usage: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
    <div>
      <Helmet>
        <title>KYC - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-900 rounded-lg shadow-lg p-8 text-white"
      >
        <h2 className="text-3xl font-semibold mb-6">KYC Check</h2>
        <div className="mb-4">
          <label htmlFor="address" className="text-sm font-medium mb-2">
            Address
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your residential address"
            required
          />
        </div>
        <div className="mb-6">
          <label htmlFor="usage" className="text-sm font-medium mb-2">
            Usage of service
          </label>
          <input
            type="usage"
            id="usage"
            name="usage"
            value={formData.usage}
            onChange={handleChange}
            className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Let us know how you'll use our service"
            required
          />
        </div>
        <div className="flex items-center justify-center">
          <button
            type="submit"
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
          >
            Verify
          </button>
        </div>
      </form>
    </div>
    </>
  );
};

export default CustomerVerification;
