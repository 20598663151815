import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Message from './Message';
import { NavLink, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import auth from '../firebase';
import useAuthentication from '../authenticate';
import { useLanguage } from '../LanguageContext';

const Login = () => {
  useAuthentication();

  const { translations } = useLanguage();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loginFailed, setLoginFailed] = useState(false);
  const [showReset, setshowReset] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const closeMessage = () => {
    setLoginFailed(false);
    setshowReset(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, formData.email, formData.password);

      // Login successful
      navigate('/sites');
    } catch (error) {
      setLoginFailed(true);
      setMessage(`${translations.login_failed} (${error.code})`);
    }
  };

  return (
    <>
      <div>
        <Helmet>
          <title>{translations.login} - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen">
        {loginFailed ? (
          <Message message={message} onClose={closeMessage} />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="bg-gray-900 rounded-lg shadow-lg p-8 text-white"
          >
            <h2 className="text-3xl font-semibold mb-6">{translations.login}</h2>
            <div className="mb-4">
              <label htmlFor="email" className="text-sm font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder={translations.enter_email}
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="text-sm font-medium mb-2">
                {translations.password}
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                placeholder={translations.enter_password}
                required
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              >
                {translations.login}
              </button>
            </div>
            <div className='pt-2'>
              <div className='pt-2'>
                <p>{translations.no_account}</p>
                <NavLink to='/register'>
                  <p className='font-bold underline hover:text-blue-500'>{translations.register}</p>
                </NavLink>
              </div>
              {showReset && (
                <div className='pt-8'>
                  <p>{translations.forgot_password}</p>
                  <NavLink to='/reset-password'>
                    <p className='font-bold underline hover:text-blue-500'>{translations.reset}</p>
                  </NavLink>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default Login;