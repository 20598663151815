import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const ProPlan = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Pro Plan - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex flex-col justify-center items-center min-h-screen text-white bg-gray-800">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl p-4">
          <h1 className="text-4xl font-semibold py-6 mt-10 lg:mt-0 mb-6">Pro Plan</h1>
          <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
            <p className="text-gray-300 mb-6">
              The Pro Plan is designed for individuals or businesses seeking advanced features and more customization options.
            </p>
            <div className="bg-gray-700 p-6 rounded-lg mb-6">
              <h2 className="text-2xl font-semibold text-gray-100 mb-4">Features</h2>
              <ul className="list-disc list-inside text-gray-200">
                <li>2 sites Included</li>
                <li>Priority Support</li>
                <li>Custom components*</li>
                <li>Higher Site Limit**</li>
                <li>Unlock all features</li>
                <li>Connect custom domain for free</li>
                <li>Free .stnz.app domain</li>
                <li>Free Hosting</li>
                <li>Free SSL Certificate</li>
              </ul>
            </div>
            <div className="text-center mb-6">
              <p className="text-2xl font-bold text-gray-100 mb-2">Pricing</p>
              <p className="text-gray-300 text-xl">€9.99/month***</p>
            </div>
            <div className="flex justify-center mb-12">
              <Link to={'/plans'} className="text-2xl bg-yellow-500 hover:bg-yellow-600 text-white font-bold px-8 py-3 rounded-lg transition transform hover:underline hover:border-2 border-white hover:scale-105">
                Subscribe
              </Link>
            </div>
              <div>
                <p className="text-gray-500 text-sm mb-2">*Custom components can be added for a small fee.</p>
                <p className="text-gray-500 text-sm mb-2">**Two (2) sites included. More sites can be added for a fee.</p>
                <p className="text-gray-500 text-sm">***Prices may vary based on subscription duration, site usage, and location. Final price will be calculated at checkout.</p>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProPlan;
