import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Legal = () => {
  return (
    <>
    <div>
      <Helmet>
        <title>Legal Portal - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <main className="text-center">
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-white">Legal Portal</h1>
          <p className='text-white py-2'>Browse our legal stuff</p>
        </section>
        <section className="mb-4 flex grid-flow-row gap-4">
          <Link
            to="/legal/privacy-policy"
            target='_blank'
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Privacy Policy
          </Link>
          <Link
            to="/legal/tos"
            target='_blank'
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Terms of Use
          </Link>
        </section>
      </main>
    </div>
    </>
  );
};

export default Legal;
