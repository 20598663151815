import React, { useEffect, useState, useRef } from 'react';
import { applyActionCode, signOut } from 'firebase/auth';
import auth from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const token = new URLSearchParams(location.search).get('oobCode');
        if (!token) {
          setVerificationStatus('Invalid verification link.');
          return;
        }
        await applyActionCode(auth, token);
        await signOut(auth);
        setVerificationStatus('Email verification successful.');
      } catch (error) {
        setVerificationStatus('Email verification failed. Please try again.');
      }
    };

    if (!isMounted.current) {
      // Check if component is mounted before running effect
      isMounted.current = true;
      verifyEmail();
    }
  }, [location.search]);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <h1 className="text-3xl font-semibold mb-6">Email Verification</h1>
        <p className={`mb-6 ${verificationStatus.includes('successful') ? 'text-green-500' : 'text-red-500'}`}>
          {verificationStatus}
        </p>
        {verificationStatus === 'Email verification successful.' && (
          <button
            onClick={handleLogin}
            className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg mr-4 focus:outline-none focus:ring focus:border-blue-300"
          >
            Login
          </button>
        )}
        <button
          onClick={handleGoHome}
          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
