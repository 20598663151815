import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';
import { Render } from "@measured/puck";
import { useParams } from 'react-router-dom';
import "@measured/puck/puck.css";
import config from '../editorConfig';

const editorLink = process.env.REACT_APP_SITE_EDITOR_LINK;

const SitePreview = () => {
  const { sid } = useParams();
  const user = useAuthentication();
  const [siteInfo, setSiteInfo] = useState(null);

  const getSiteInfo = async () => {
    if (siteInfo) {
      return;
    }
    try {
      const response = await fetch(editorLink + 'getSite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId: user.uid, siteId: sid })
      });

      if (!response.ok) {
        throw new Error('Failed to get site data');
      }

      const data = await response.json();
      const structuredData = structureSiteInfo(data.siteInfo);
      setSiteInfo(structuredData);
    } catch (error) {
      console.error('Error getting site data:', error);
    }
  };

  useEffect(() => {
    if (user) {
      getSiteInfo();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const structureSiteInfo = (siteInfo) => {
    const structuredData = {
      content: [],
      root: {}
    };

    siteInfo.content.forEach(item => {
      const newItem = {
        type: item.type,
        props: { ...item.props }
      };
      structuredData.content.push(newItem);
    });

    return structuredData;
  };

  return (
    <div className="h-screen bg-white">
      <Helmet>
        <title>Editor - Stenz Digital</title>
      </Helmet>
      {siteInfo ? (
        <Render config={config} data={siteInfo} />
      ) : (
        <>
          <div>Loading...</div>
        </>
      )}
    </div>
  );
};

export default SitePreview;
