import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { signInWithEmailAndPassword, verifyBeforeUpdateEmail } from 'firebase/auth';
import auth from '../firebase';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';

const Account = () => {
  const user = useAuthentication();

  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(true);

 const navigate = useNavigate();

 useEffect(() => {
  if(user){
    setLoading(false);
  }
}, [user, setLoading])

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newEmail !== confirmEmail) {
      setErrorMessage('Emails do not match.');
      setShowMessage(true);
      return;
    }

    try {
      // Sign in with email and password
      await signInWithEmailAndPassword(auth, email, password);
      
      // Send verification email to new email
      const currentUser = auth.currentUser;
      await verifyBeforeUpdateEmail(currentUser, newEmail);
      
      // Inform user that email has been sent and log out
      setErrorMessage('A verification email has been sent to your new email address. You will be logged out.');
      setShowMessage(true); // Display the success message
      
      const msg = 'A verification email has been sent to your new email address. You have been logged out.';

      navigate(`/e/?msg=${encodeURIComponent(msg)}`);

    } catch (error) {
      setErrorMessage(`Failed to verify your information. Please try again later. (${error.code})`);
      setShowMessage(true); // Display the error message
    }
  };

  const handleReset = async () => {
    navigate('/reset-password');
  }

  /*const handleOpenSecurity = async () => {
    navigate('/account/security');
  }*/

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>Account - Stenz Digital</title>
      </Helmet>
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Account</header>
        <div className="mb-4 py-2">
          <p className="text-xl text-gray-400">Manage account</p>
          {loading ? (
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '10px' }}></div>
          ) : (
            <>
            {(user &&
              <p className='text-lg text-gray-500'>{user.email}</p>
            )}
            </>
            )}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 flex">
            <div className="bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => setShowChangeEmailModal(true)}>Change Email</div>
          </div>
          <div className="p-4 flex">
            <div onClick={handleReset} className="bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Reset Password</div>
          </div>
          <div className="p-4 flex">
            <div className="bg-gray-600 p-4 flex-grow rounded-lg text-red-400 cursor-not-allowed hover:bg-gray-700">Delete Account</div>
          </div>
          <div className="p-4 flex">
            <div /*onClick={handleOpenSecurity}*/ className="bg-gray-600 p-4 flex-grow rounded-lg text-gray-300 cursor-not-allowed hover:bg-gray-700">Security Settings</div>
          </div>
        </div>
      </div>
      {showChangeEmailModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">Change Email</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="block text-sm font-medium mb-2">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="newEmail" className="block text-sm font-medium mb-2">New Email</label>
                <input
                  type="email"
                  id="newEmail"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Enter your new email"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="confirmEmail" className="block text-sm font-medium mb-2">Confirm Email</label>
                <input
                  type="email"
                  id="confirmEmail"
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Confirm your new email"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowChangeEmailModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="submit" className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Submit</button>
              </div>
              {showMessage && (
              <div className="mt-4 bg-gray-800 text-red-600 p-2 rounded">
                {errorMessage}
              </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
