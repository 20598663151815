import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import useAuthentication from '../authenticate';

const Ad = () => {
const { aid } = useParams();
useAuthentication();

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>{aid} - Stenz Digital</title>
      </Helmet>
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Ad Campaign Management</header>
        <div className="mb-4">
          <p className="text-xl text-gray-400">{aid}</p>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Go Live</div>
          </div>
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Edit Campaign</div>
          </div>
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Settings</div>
          </div>
          <div className="p-4">
            <div className="bg-gray-600 p-4 rounded-lg text-red-400 cursor-pointer hover:bg-gray-700">Go Offline</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ad;
