import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';
import { useNavigate } from 'react-router-dom';

const Billing = () => {
  const user = useAuthentication();
  const customerPortalLink = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscription, setSubscription] = useState('');

  useEffect(() => {
    if (user && user.user) {
      setSubscriptionStatus(user.user.subscriptionStatus || 'Inactive');
      setSubscription(user.user.subscriptionName || 'No Subscription') 
      setLoading(false);
    }
  }, [user]);

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>Billing - Stenz Digital</title>
      </Helmet>
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Billing</header>
        {loading ? (
          <div className="mb-4">
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '200px', height: '20px' }}></div>
            <div className="animate-pulse bg-gray-600 p-4 rounded-lg mb-2" style={{ width: '300px', height: '20px' }}></div>
          </div>
        ) : (
          <>
            <div className="mb-4 border-b border-gray-600 pb-2">
              <p className="text-xl text-gray-400 font-semibold">Your subscription:</p>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className='mb-4'>
                <div className="border-b border-gray-600 pb-2">
                  <p className="text-xl text-gray-400 font-semibold">Subscription status:</p>
                  <p className={`text-xl ${subscriptionStatus === 'active' ? 'text-green-400' : 'text-yellow-400'} font-bold`}>{subscriptionStatus.charAt(0).toUpperCase() + subscriptionStatus.slice(1)}</p>
                </div>
                <div className="border-b border-gray-600 pb-2">
                  <p className="text-xl text-gray-400 font-semibold">Plan:</p>
                  <p className="text-xl text-gray-400 font-bold">{subscription}</p>
                </div>
              </div>
              <div className="text-center">
                <div className="bg-gray-600 p-2 mt-2 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => navigate('/plans')}>View Plans</div>
                <div className="bg-gray-600 p-2 mt-2 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => window.location.replace(customerPortalLink)}>Manage Plan</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Billing;