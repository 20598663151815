import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const StarterPlan = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Starter Plan - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex flex-col justify-center items-center min-h-screen text-white bg-gray-800">
        <div className="w-full sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl p-4">
          <h1 className="text-4xl font-semibold py-6 mt-10 lg:mt-0 mb-6">Starter Plan</h1>
          <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
            <p className="text-gray-300 mb-6">
              The Starter Plan is perfect for individuals or small businesses looking for a cost-effective solution with essential features.
            </p>
            <div className="bg-gray-700 p-6 rounded-lg mb-6">
              <h2 className="text-2xl font-semibold text-gray-100 mb-4">Features</h2>
              <ul className="list-disc list-inside text-gray-200">
                <li>1 Site Included</li>
                <li>Unlock all features</li>
                <li>Connect custom domain for free</li>
                <li>Free .stnz.app domain</li>
                <li>Free Hosting</li>
                <li>Free SSL Certificate</li>
              </ul>
            </div>
            <div className="text-center mb-6">
              <p className="text-2xl font-bold text-gray-100 mb-2">Pricing</p>
              <p className="text-gray-300 text-xl">€9.99/month*</p>
            </div>
            <div className="flex justify-center mb-8">
              <Link to={'/plans'} className="text-2xl bg-yellow-500 hover:bg-yellow-600 text-white font-bold px-8 py-3 rounded-lg transition transform hover:underline hover:border-2 border-white hover:scale-105">
                Subscribe
              </Link>
            </div>
            <p className="text-gray-500 text-sm">*Prices may vary based on subscription duration, site usage, and location. Final price will be calculated at checkout.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StarterPlan;
