import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
      <div>
        <Helmet>
          <title>Contact - Stenz Digital</title>
        </Helmet>
      </div>
      <div className="flex justify-center items-center h-screen bg-gradient-to-r bg-gray-800 to-teal-500">
        <main className="text-center p-6 bg-gray-900 bg-opacity-80 rounded-lg shadow-lg max-w-lg mx-auto">
          <section className="mb-6">
            <h1 className="text-4xl font-bold text-white">Contact Us</h1>
            <p className="text-white mt-2">
              We’d love to hear from you! Whether you need help, have a question, feedback, or just want to say hello, feel free to reach out.
            </p>
          </section>
          <section className="mb-6">
            <p className='text-white font-semibold text-lg'>
              Email us at: <Link to="mailto:contact@stenzdigital.com" className="text-blue-600 hover:text-blue-800">contact@stenzdigital.com</Link>
            </p>
          </section>
          <section>
            <Link
              to="mailto:contact@stenzdigital.com"
              className="inline-block px-6 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out hover:scale-105"
            >
              Send an Email
            </Link>
          </section>
        </main>
      </div>
    </>
  );
};

export default Contact;
