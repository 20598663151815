import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';

const ReauthOnboarding = () => {
const user = useAuthentication();

const getOnboardingLink = process.env.REACT_APP_ONBOARDING_LINK

const handleOnboarding = async () => {
  try {
    const response = await fetch(getOnboardingLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: user.uid }),
    });
    const data = await response.json();
    if (data.url) {
      window.location.replace(data.url);
    } else {
      console.error('Failed to initiate onboarding...');
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

  return (
    <>
    <div>
      <Helmet>
        <title>Reauth Onboarding - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <main className="text-center">
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-white">Reauth Onboarding</h1>
          <p className='text-white mt-4 text-lg'>If you need to update your details or finish the onboarding process.</p>
          <p className='text-white mt-2 text-lg'>You can do that <Link onClick={handleOnboarding} className='font-bold underline hover:text-blue-500'>here</Link>.</p>
        </section>
        <section className="mb-4">
          <Link
            onClick={handleOnboarding}
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Reauth
          </Link>
        </section>
      </main>
    </div>
    </>
  );
};

export default ReauthOnboarding;
