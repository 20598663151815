import React, { useEffect, useState, useRef } from 'react';
import { checkActionCode, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import auth from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate2';

const apiLink = process.env.REACT_APP_API_LINK;


const EmailSignin = () => {
  const user = useAuthentication();
  const [verificationStatus, setVerificationStatus] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isMounted = useRef(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEmailSubmitted(true);
  };

  useEffect(() => {
    const validate = async () => {
      try {
        const token = new URLSearchParams(location.search).get('oobCode');
        if (!token) {
          setVerificationStatus('Invalid verification link.');
          return;
        }

        if (user) {
          await signOut(auth);
        }

        // Verify the oobCode using checkActionCode
        try {
          await checkActionCode(auth, token);
        } catch (error) {
          console.log('Error verifying action code:', error);
          setVerificationStatus('Invalid or expired action code. Please try again.');
          return;
        }

        // Ensure email and password are available before attempting to sign in
        if (!email || !password) {
          setVerificationStatus('Email and password are required to complete sign-in.');
          return;
        }

        await signInWithEmailAndPassword(auth, email, password);
        setVerificationStatus('Logged in successfully!');
        await handlePostLogin();
      } catch (error) {
        setVerificationStatus('Failed to login. Please try again.');
      }
    };

    if (isMounted.current && isEmailSubmitted) {
      validate();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailSubmitted, location.search, email, password]);

  const handlePostLogin = async () => {
    try
    {
      // Fetch additional user information from backend
      const response = await fetch(apiLink + 'trustLogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      if (!response.ok) {
        if (user) {
          await signOut(auth);
        }
        setVerificationStatus('Failed to login. Please try again. (2faE/trust-error)');
      }

      if (response.ok)
      {
        navigate('/');
      }
    }
    catch (error)
    {
      if (user) {
        await signOut(auth);
      }
      setVerificationStatus('Failed to login. Please try again. (2faE/internal-error)');
    }
  };

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
        <h1 className="text-3xl font-semibold mb-6">Login</h1>
        {!isEmailSubmitted ? (
          <form onSubmit={handleSubmit}>
            <label htmlFor="email" className="text-sm font-medium mb-2">
              Please enter your email to complete login
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your email"
              required
            />
            <label htmlFor="password" className="text-sm font-medium mb-2 mt-4">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handleChange}
              className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter your password"
              required
            />
            <button
              type="submit"
              className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300 mt-4"
            >
              Login
            </button>
          </form>
        ) : (
          <p className={`mb-6 ${verificationStatus.includes('successful') ? 'text-green-500' : 'text-red-500'}`}>
            {verificationStatus}
          </p>
        )}
        <button
          onClick={handleGoHome}
          className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300 mt-2"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EmailSignin;
