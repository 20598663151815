import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';
import Message from './Message';
import { useNavigate } from 'react-router-dom';

const Email = () => {
  useAuthentication();
  const [showMessage, setShowMessage] = useState(true);
  const navigate = useNavigate();

  const closeMessage = () => {
    setShowMessage(false);
    navigate('/home');
  };

  return (
    <div className="flex justify-center items-center h-screen text-white">
      <Helmet>
        <title>Email - Stenz Digital</title>
      </Helmet>
        {showMessage ? (
          <>
            <Message message={'Email setup is currently disabled'} onClose={closeMessage} />
          </>
        ) : (
          <>
              <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white">
              <header className="text-3xl font-semibold text-gray-300 mb-4">Emails</header>
              <div className="mb-4">
                <p className="text-xl text-gray-400">Manage Emails</p>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4">
                  <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Manage Email Accounts</div>
                </div>
                <div className="p-4">
                  <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Quick Add Email Account</div>
                </div>
                <div className="p-4">
                  <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Email Forwarding</div>
                </div>
                <div className="p-4">
                  <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700">Advanced</div>
                </div>
              </div>
              </div>
          </>
        )}
    </div>
  );
};

export default Email;