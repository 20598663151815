import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useAuthentication from '../authenticate';

const Subscribed = () => {
useAuthentication();

  return (
    <>
    <div>
      <Helmet>
        <title>Subscribed! - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <main className="text-center">
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-white"><span className='underline'>Thank you</span> for <span className='italic font-extrabold'>subscribing</span>!</h1>
          <p className='text-white mt-4 text-lg'>Your plan is ready!</p>
          <p className='text-white mt-2 text-lg'>Don't hesitate to <Link to={"mailto:contact@stenzdigital.com"} className='font-bold underline hover:text-blue-500'>contact</Link> us if you experience any problems.</p>
          <p className='text-white mt-2 text-lg'>Cancel at anytime <Link to={"/billing"} className='font-bold underline hover:text-blue-500'>here</Link>.</p>
        </section>
        <section className="mb-4">
          <Link
            to="/"
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            Get started
          </Link>
        </section>
      </main>
    </div>
    </>
  );
};

export default Subscribed;
