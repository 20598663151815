import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';

const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK


const SiteDetails = () => {
  const user = useAuthentication();
  const { sn, sid } = useParams();
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [template, setTemplate] = useState(null);
  const [customDomain, setCustomDomain] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        const template = siteFound?.template;
        const created_at = siteFound?.created_at;
      
        setCreatedAt(created_at);
        setTemplate(template);
    
        if (!siteFound || !template) {
          navigate(`/sites/${sn}/${sid}`);
        }

        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomain(siteFound?.customDomain);
        }
      } catch (error) {
        console.error(error);
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    const fetchData = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        
        if(!siteFound?.hosted)
        {
          navigate(`/sites/${sn}/${sid}`);
        }

        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if (data.status && sn === siteFound.name) {
          setSiteData(data);
          await fetchUserSites();
          setLoading(false);
        } else {
          navigate(`/sites/${sn}/${sid}`);
        }
      } catch (error) {
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    // Fetch site data initially
    fetchData();

  }, [sid, sn, navigate, user]);

  // Function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB') + ' @ ' + date.toLocaleTimeString();
  };

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen p-4 sm:p-6 lg:p-4 text-white mt-16 sm:mt-0`}>
      <Helmet>
        <title>{customDomain ? customDomain.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white max-w-xl w-full">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Site Details</header>
        <div className="mb-4">
        {loading ?
              <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
              </div>  :
              <p className="text-xl text-gray-400">
              <Link
                target={siteData.status === 'Published' ? `_blank` : ''}
                to={siteData.status === 'Published' ? (customDomain ? customDomain : `https://${sn}.stnz.app`) : ''}
                className={`font-bold underline hover:text-blue-500 ${siteData.status !== 'Published' && 'cursor-not-allowed'}`}
              >
                {customDomain ? customDomain.replace('https://', '') : sn }
              </Link>
            </p>
          }
          <div className="flex flex-row md:items-center mb-4 text-xl text-gray-400">
            <p className='font-semibold'>Status:</p>
            {loading ? (
              <div className="ml-2 flex items-center">
                <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
              </div>
            ) : (
              <div className="ml-2 flex items-center">
                <p className={`${siteData.status === 'Published' ? 'text-green-400' : siteData.status === 'Unpublished' ? 'text-yellow-400' : 'text-red-400'}`}>
                  {siteData.status}
                </p>
              </div>
            )}
          </div>
          {!loading ? (
            <>
              {user && (
                <>
                  <div className='text-xl text-gray-400 mb-4'>
                    <p className='font-semibold'>Owner: </p><p className='font-bold'>{user.email}</p>
                  </div>
                </>
              )}
              <div className='mb-4'>
                <p className='font-semibold text-xl text-gray-400'>Template: <p className='font-bold'>{template}</p></p>
              </div>
              <div className="mb-4">
                <p className='font-semibold text-xl text-gray-400'>URL:</p>
                <p className="text-xl text-gray-400">
                <Link
                  target={siteData.status === 'Published' ? `_blank` : ''}
                  to={siteData.status === 'Published' ? (customDomain ? customDomain : `https://${sn}.stnz.app`) : ''}
                  className={`font-bold underline hover:text-blue-500 ${siteData.status !== 'Published' && 'cursor-not-allowed'}`}
                >
                  {customDomain ? customDomain : `https://${sn}.stnz.app` }
                </Link>
              </p>
              </div>
              {createdAt && (
                <>
                  <div className="mb-4">
                    <p className="text-xl text-gray-400"><p className='font-semibold'>Created At:</p><p className='font-bold'>{formatDate(createdAt)}</p></p>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="ml-2">
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="pt-2">
          <Link to={`/sites/${sn}/${sid}/settings`}>
            <p className="font-bold underline hover:text-blue-500">Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
