import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const CookieBanner = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted'));

  const acceptCookies = () => {
    // set the expiration date to 1 year from the current date
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    // set a cookie to indicate that the user has accepted cookies
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  const declineCookies = () => {
    window.location.replace('/legal/privacy-policy');
  };

  return (
    <>
      {!cookiesAccepted && (
        <div className="fixed bottom-0 left-0 w-full z-50 bg-gray-900 text-white p-4">
          <div className="max-w-4xl mx-auto flex flex-col items-center justify-between">
            <p className="mb-4 text-center">
              Our website uses cookies to improve your experience and to personalize the content and advertising you see. 
              By continuing to use our website, you consent to our use of cookies. For more information on how we use cookies and how you can manage your preferences, 
              please see our 
              <Link 
                to='/legal/privacy-policy'
                target='_blank' rel='noopener noreferrer'
                className='font-bold underline hover:text-blue-500 text-sm ml-1'
              >
                Privacy Policy
              </Link>
            </p>
            <div className="flex justify-center">
              <button
                id="cookie-banner-accept-button"
                className="text-white bg-blue-500 hover:bg-blue-600 px-8 py-2 mx-2 uppercase font-bold text-sm rounded"
                onClick={acceptCookies}
              >
                Accept
              </button>
              <button
                id="cookie-banner-decline-button"
                className="text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 mx-2 uppercase font-bold text-sm rounded"
                onClick={declineCookies}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
