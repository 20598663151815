import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../LanguageContext';

const NotFound = () => {
  const { translations } = useLanguage();
  return (
    <>
    <div>
      <Helmet>
        <title>{translations.fourofour} - Stenz Digital</title>
      </Helmet>
    </div>
    <div className="flex justify-center items-center h-screen">
      <main className="text-center">
        <section className="mb-8">
          <h1 className="text-4xl font-bold text-white">{translations.fourofour}</h1>
          <p className='text-white'>{translations.oops}</p>
          <p className='text-white'>{translations.broken_link}</p>
        </section>
        <section className="mb-4">
          <Link
            to="/"
            className="inline-block px-6 py-3 text-white bg-blue-500 rounded-md hover:bg-blue-600"
          >
            {translations.back}
          </Link>
        </section>
      </main>
    </div>
    </>
  );
};

export default NotFound;
