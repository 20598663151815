import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../authenticate';
import BadWordsFilter from 'bad-words';
import Message from './Message';
import { FaCheckCircle,  } from 'react-icons/fa';
import { MdCancel } from "react-icons/md";


const getStatusLink = process.env.REACT_APP_SITE_EDITOR_LINK
const apiLink = process.env.REACT_APP_API_LINK;

const DomainManagement = () => {
  const user = useAuthentication();
  const { sn, sid } = useParams();
  const [loading, setLoading] = useState(true);
  const [siteData, setSiteData] = useState(null);
  const [customDomain, setCustomDomain] = useState('');
  const [customDomainDNS, setCustomDomainDNS] = useState(false);
  const [customDomainAdded, setCustomDomainAdded] = useState(false);
  const [cnameRecord, SetCnameRecord] = useState(null);
  const [aRecord, setARecord] = useState(null);
  const [cd, setCd] = useState(null);
  const [showCustomDomainModal, setShowCustomDomainModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [customDomainA, setCustomDomainA] = useState('');
  const navigate = useNavigate();

  const fetchDNSRecords = async (customDomain) => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
  
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      
      if(!siteFound?.hosted)
      {
        navigate(`/sites/${sn}/${sid}`);
      }

      if(!customDomain)
      {
        if(!cd && !siteFound?.customDomain)
        {
          return;
        }
      }

      const response = await fetch(apiLink + 'getDNSRecords', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid, siteId: sid, hostedId: siteFound?.hosted }),
      });
      if (!response.ok) {
        setMessage('Failed to get DNS records... Please try again later. (dns-get/failed)');
        setShowMessage(true);
        return;
      }
      const data = await response.json();
      if(data && response.ok)
      {
        setARecord(data.A);
        SetCnameRecord(data.CNAME);
      }
    } catch (error) {
      navigate(`/sites/${sn}/${sid}`);
    }
  };

  const fetchDNS = async () => {
    try {
      if (!user || !user.user || !user.user.sites) {
        return;
      }
  
      const sites = user.user.sites;
      const siteFound = sites.find(site => site.siteId === sid);
      
      if(!siteFound?.hosted)
      {
        navigate(`/sites/${sn}/${sid}`);
      }

      if(!siteFound?.customDomain && !customDomainAdded)
      {
        return;
      }

      const response = await fetch(apiLink + 'getDNS', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customDomain: siteFound?.customDomain ? siteFound?.customDomain : cd, userId: user.uid, siteId: sid, hostedId: siteFound?.hosted }),
      });
      if (!response.ok) {
        setCustomDomainDNS(false);
        await fetchDNSRecords();
        return;
      }
      const data = await response.json();
      if(data && response.ok)
      {
        setARecord(null);
        SetCnameRecord(null);
        setCustomDomainDNS(true);
        const sslRes = await fetch(apiLink + 'addSSL', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        const sslData = await sslRes.json();
        if(!sslRes.ok && data.error)
        {
          setMessage(`Failed to add SSL/TLS certificate for ${customDomain}. Please try again later. ${sslData.error}`);
          setShowMessage(true);
        }
      }
    } catch (error) {
      navigate(`/sites/${sn}/${sid}`);
    }
  };

  useEffect(() => {
    if(customDomainAdded)
    {
      fetchDNSRecords();
      setCustomDomainAdded(false);
    }

    const fetchUserSites = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        const customDomain = siteFound?.customDomain;
      
        setCd(customDomain);
    
        if (!siteFound) {
          navigate(`/sites/${sn}/${sid}`);
        }
        await fetchDNS();
      } catch (error) {
        console.error(error);
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    const fetchData = async () => {
      try {
        if (!user || !user.user || !user.user.sites) {
          return;
        }
    
        const sites = user.user.sites;
        const siteFound = sites.find(site => site.siteId === sid);
        
        if(!siteFound?.hosted)
        {
          navigate(`/sites/${sn}/${sid}`);
        }

        if (siteFound && siteFound?.customDomain)
        {
          setCustomDomainA(siteFound?.customDomain);
        }

        const response = await fetch(getStatusLink + 'getStatus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ siteId: sid, userId: user.uid }),
        });
        if (!response.ok) {
          navigate(`/sites/${sn}/${sid}`);
          return;
        }
        const data = await response.json();
        if (data.status && sn === siteFound.name) {
          setSiteData(data);
          await fetchUserSites();
          setLoading(false);
        } else {
          navigate(`/sites/${sn}/${sid}`);
        }
      } catch (error) {
        navigate(`/sites/${sn}/${sid}`);
      }
    };

    // Fetch site data initially
    fetchData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid, sn, navigate, user]);

  const handleCloseMessage = () => {
    if (!user.emailVerified) {
      navigate('/account');
    }
    setShowMessage(false);
  };

  const removeCustomDomain = async () => {
    try
    {
      //Remove custom domain
      if (cd && user && user.user.sites)
        {
          const sites = user.user.sites;
          const siteFound = sites.find(site => site.siteId === sid);
          const hosted = siteFound?.hosted;
          const dm = siteFound?.customDomain.replace('https://', '');

          const response = await fetch(apiLink + 'removeCustomDomain', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customDomain: dm, userId: user.uid, siteId: sid, hostedId: hosted }),
          });
    
          const data = await response.json();
    
          if (data.error || !response.ok) {
            setMessage(`Failed to remove your custom domain. Please try again later. (${data.error})`);
            setShowCustomDomainModal(false);
            setShowMessage(true);
            return;
          }
    
          setMessage(`Your domain ${customDomain} was removed successfully.`);
          setShowMessage(true);
          setShowCustomDomainModal(false);
          setCd(null);
          return;
        }
    }
    catch (error)
    {
      setMessage('Failed to remove your custom domain. Please try again later. (domain/remove-failed)');
      setCustomDomain('');
      setShowCustomDomainModal(false);
      setShowMessage(true);
    }
  }

  const handleCustomDomain = async () => {
    try {
      //Profanity filter
      const filter = new BadWordsFilter();
      if(filter.isProfane(customDomain))
      {
        setMessage('Detected profanity in domain. Please refer to our Terms of Service.');
        setShowCustomDomainModal(false);
        setShowMessage(true);
        return;
      }

      const cleanSiteName = async () => {
        return customDomain.replace(/\s/g, '');
      }
    
      const sn = await cleanSiteName();

      const isValidDomain = (domain) => {
        const domainPattern = /^(?![0-9]+$)(?!-)[a-zA-Z0-9-]{1,63}(?<!-)(\.[a-zA-Z]{2,})+$/;
      
        return domainPattern.test(domain);
     };
    

      if (!isValidDomain(sn)) {
        setMessage('Custom domains must be actual domains. (e.g. domain.com)');
        setShowCustomDomainModal(false);
        setShowMessage(true);
        return;
      }

      const response = await fetch(apiLink + 'addCustomDomain', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customDomain: sn, userId: user.uid, siteId: sid }),
      });

      const data = await response.json();

      if (data.error || !response.ok) {
        setMessage(`Failed to add your custom domain. Please try again later. (${data.error})`);
        setShowCustomDomainModal(false);
        setShowMessage(true);
        return;
      }

      setMessage(`Your domain ${customDomain} was added successfully.`);
      setShowMessage(true);
      setShowCustomDomainModal(false);
      setCd("https://" + customDomain);
      setCustomDomainAdded(true);
      await fetchDNSRecords(customDomain);
    } catch (error) {
      setMessage('Failed to add your custom domain. Please try again later. (site/domain-failed)');
      setCustomDomain('');
      setShowCustomDomainModal(false);
      setShowMessage(true);
    }
  };

  return (
    <div className={`flex flex-col justify-center items-center min-h-screen p-4 sm:p-6 lg:p-4 text-white mt-16 sm:mt-0`}>
      <Helmet>
        <title>{customDomainA ? customDomainA.replace('https://', '') : sn } - Stenz Digital</title>
      </Helmet>
      {showMessage && (
        <Message
          message={message}
          onClose={handleCloseMessage}
        />
      )}
      {!showMessage && (
      <div className="bg-gray-900 rounded-lg shadow-lg p-8 text-white max-w-xl w-full">
        <header className="text-3xl font-semibold text-gray-300 mb-4">Domain Management</header>
        <div className="mb-4">
          {loading ?
            <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}>
            </div>  :
            <p className="text-xl text-gray-400">
            <Link
              target={siteData.status === 'Published' ? `_blank` : ''}
              to={siteData.status === 'Published' ? (customDomainA ? customDomainA : `https://${sn}.stnz.app`) : ''}
              className={`font-bold underline hover:text-blue-500 ${siteData.status !== 'Published' && 'cursor-not-allowed'}`}
            >
              {customDomainA ? customDomainA.replace('https://', '') : sn }
            </Link>
          </p>
          }
          <div className="flex flex-row md:items-center mb-4 text-xl text-gray-400">
            <p className='font-semibold'>Status:</p>
            {loading ? (
              <div className="ml-2 flex items-center">
                <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
              </div>
            ) : (
              <div className="ml-2 flex items-center">
                <p className={`${siteData.status === 'Published' ? 'text-green-400' : siteData.status === 'Unpublished' ? 'text-yellow-400' : 'text-red-400'}`}>
                  {siteData.status}
                </p>
              </div>
            )}
          </div>
          {!loading ? (
            <>
              {user && (
                <>
                  <div className='text-xl text-gray-400 mb-4'>
                    <p className='font-semibold'>Owner: </p><p className='font-bold'>{user.email}</p>
                  </div>
                </>
              )}
              <div className="mb-4">
                <p className="text-xl text-gray-400"><p className='font-semibold'>URL:</p>
                  <Link
                  target={siteData && siteData.status === 'Published' ? `_blank` : ''}
                  to={siteData && siteData.status === 'Published' ? `https://${sn}.stnz.app` : ''}
                  className={`font-bold underline hover:text-blue-500 ${siteData && siteData.status !== 'Published' && 'cursor-not-allowed'}`}
                >
                  {sn}.stnz.app
                </Link>
                </p>
              </div>
              {cd && (
                <>
                  <div className="mb-4">
                  <p className="text-xl text-gray-400"><p className='font-semibold'>Custom Domain:</p>
                  <Link
                  target={siteData && siteData.status === 'Published' ? `_blank` : ''}
                  to={siteData && siteData.status === 'Published' ? `${cd}` : ''}
                  className={`font-bold underline hover:text-blue-500 ${siteData && siteData.status !== 'Published' && 'cursor-not-allowed'}`}
                >
                  {cd}
                  <span className={`${customDomainDNS ? 'text-green-500' : 'text-red-500'}`} > {customDomainDNS ? <FaCheckCircle style={{ display: 'inline-block' }} /> : <MdCancel style={{ display: 'inline-block' }} />}</span>
                </Link>
                </p>
                {cnameRecord && aRecord && (
                  <div>
                  <p className='font-semibold'>Did you remember to setup your DNS correctly?</p>
                  <p className='font-semibold'>If your DNS is correct - Please wait and try again later.</p>
                    <Link to={`https://stenzdigital.com/guide/dns`}>
                      <p className="font-bold underline hover:text-blue-500">DNS Setup Guide</p>
                    </Link>
                    <button className="font-bold underline hover:text-blue-500 cursor-pointer" onClick={fetchDNS}>Refresh</button>
                    <p className="mb-2 font-semibold">Your DNS should look like this:</p>
                    <div className="border border-gray-300 p-4 rounded-md">
                      <div className="mb-4">
                        <p className="font-bold mb-1">Type</p>
                        <p className='font-semibold'>CNAME Record</p>
                      </div>
                      <div className="mb-4">
                        <p className="font-bold mb-1">Host</p>
                        <p className='font-semibold'>@</p>
                      </div>
                      <div className="mb-4">
                        <p className="font-bold mb-1">Value</p>
                        <p className='font-semibold'>{cnameRecord}</p>
                        <button className="text-blue-500 hover:underline focus:outline-none" onClick={() => navigator.clipboard.writeText(cnameRecord)}>
                          Copy
                        </button>
                      </div>
                    </div>
                    <div className="border border-gray-300 p-4 rounded-md mt-4">
                      <div className="mb-4">
                        <p className="font-bold mb-1">Type</p>
                        <p className='font-semibold'>A Record</p>
                      </div>
                      <div className="mb-4">
                        <p className="font-bold mb-1">Host</p>
                        <p className='font-semibold'>@</p>
                      </div>
                      <div className="mb-4">
                        <p className="font-bold mb-1">Value</p>
                        <p className='font-semibold'>{aRecord}</p>
                        <button className="text-blue-500 hover:underline focus:outline-none" onClick={() => navigator.clipboard.writeText(aRecord)}>
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                  </div>
                </>
              )}
              <div className={`grid pt-2`}>
                  <div className="p-4 flex justify-center">
                      <div className="bg-gray-600 p-4 rounded-lg text-gray-300 cursor-pointer hover:bg-gray-700" onClick={() => setShowCustomDomainModal(true)}>
                        {cd ? 'Remove Custom Domain' : 'Add Custom Domain'}
                      </div>
                  </div>
              </div>
            </>
          ) : (
            <>
              <div className="ml-2">
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
                <div className='pt-4'>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '100px', height: '25px' }}></div>
                  <div className="animate-pulse bg-gray-600 rounded-lg mb-2" style={{ width: '200px', height: '25px' }}></div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="pt-2">
          <Link to={`/sites/${sn}/${sid}/settings`}>
            <p className="font-bold underline hover:text-blue-500">Back</p>
          </Link>
        </div>
      </div>
      )}
      {showCustomDomainModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 rounded-lg p-8 text-white w-96">
            <h2 className="text-2xl font-semibold mb-4">{cd ? 'Remove Custom Domain' : 'Add Custom Domain'}</h2>
            <form>
              <div className="mb-4">
                {!cd ? (
                  <>
                    <label htmlFor="customDomain" className="block text-sm font-medium mb-2">Custom Domain:</label>
                    <input
                      type="text"
                      id="customDomain"
                      value={customDomain}
                      onChange={(e) => setCustomDomain(e.target.value)}
                      className="bg-gray-700 rounded-lg w-full py-2 px-4 text-white placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                      placeholder="Enter custom domain"
                      required
                    />
                  </>
                ) : (
                  <>
                    <p className='text-lg font-semibold'>Custom Domain: <span className='font-bold'>{cd}</span></p>
                    <p className='text-lg'>Are you sure you want to remove your custom domain?</p>
                    <p className='bg-red-500 text-lg text-center'>This action is irreversible </p>
                  </>
                )}
              </div>
              <div className="flex justify-end">
                <button type="button" onClick={() => setShowCustomDomainModal(false)} className="mr-4 bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">Cancel</button>
                <button type="button" onClick={cd ? removeCustomDomain : handleCustomDomain} className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg focus:outline-none focus:ring focus:border-blue-300">{cd ? 'Remove Custom Domain' : 'Add Custom Domain'}</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DomainManagement;
