import React from 'react';
import ProductSection from './components/ecom/ProductSection';
import ProductPreview from './components/ecom/ProductPreview';
import Cart from './components/ecom/Cart';
import Header from './components/ecom/Header';
import config from './editorConfig';
import ImageSection from './components/confel/ImageSection';

const eConfig = {
  components: {
    ...config.components,
    ProductSection: {
      fields: {
        title: {
          label: "Section Title",
          type: "text",
        },
        priceColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        titleFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonTitle: {
          label: "Button Title",
          type: "text",
        },
      },
      defaultProps: {
        title: "Products",
        priceColor: "text-gray-500",
        backgroundColor: "bg-white",
        textColor: "text-black",
        titleFontSize: "text-xl",
        productFontSize: "text-md",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        buttonTitle: "View Product",
      },
      render: (props) => {
        return <ProductSection {...props} />;
      },
    },
    Cart: {
      fields: {
        title: {
          label: "Section Title",
          type: "text",
        },
        priceColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        priceFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceFontSize" className="block text-sm font-medium mb-2">
                Price Font Size
              </label>
              <select
                id="priceFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        titleFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        buttonTitle: {
          label: "Button Title",
          type: "text",
        },
      },
      defaultProps: {
        title: "Cart",
        priceColor: "text-gray-500",
        priceFontSize: "text-md",
        backgroundColor: "bg-white",
        textColor: "text-black",
        titleFontSize: "text-xl",
        productFontSize: "text-md",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        buttonTitle: "Checkout",
      },
      render: (props) => {
        return <Cart {...props} />;
      },
    },
    Header: {
      fields: {
        title: {
          label: "Header Title",
          type: "text",
        },
        links: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Header Links</label>
              {value.map((link, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    type="text"
                    className="rounded-l-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="URL"
                    value={link.url}
                    onChange={(e) => {
                      const newValue = [...value];
                      newValue[index].url = e.target.value;
                      onChange(newValue);
                    }}
                  />
                  <input
                    type="text"
                    className="rounded-r-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="Text"
                    value={link.text}
                    onChange={(e) => {
                      const newValue = [...value];
                      newValue[index].text = e.target.value;
                      onChange(newValue);
                    }}
                  />
                  <button
                    type="button"
                    className="ml-2 py-2 px-4 bg-red-500 text-white rounded-lg"
                    onClick={() => {
                      const newValue = [...value];
                      newValue.splice(index, 1);
                      onChange(newValue);
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="py-2 px-4 bg-green-500 text-white rounded-lg"
                onClick={() => {
                  onChange([...value, { url: "", text: "" }]);
                }}
              >
                Add Link
              </button>
            </div>
          ),
        },
        logo: {
          type: "custom",
          render: ({ value, onChange }) => (
            <ImageSection value={value} onChange={onChange} />
          ),
        },
        textColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        fontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="fontSize" className="block text-sm font-medium mb-2">
                Font Size
              </label>
              <select
                id="fontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        fontStyle: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="fontStyle" className="block text-sm font-medium mb-2">
                Font Style
              </label>
              <select
                id="fontStyle"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="font-bold">Bold</option>
                <option value="italic">Italic</option>
                <option value="">Normal</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        cartButtonText: {
          label: "Cart Button Text",
          type: "text",
        },
        cartButtonFontSize: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonFontSize" className="block text-sm font-medium mb-2">
                Cart Button Font Size
              </label>
              <select
                id="cartButtonFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        cartButtonFontStyle: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonFontStyle" className="block text-sm font-medium mb-2">
                Cart Button Font Style
              </label>
              <select
                id="cartButtonFontStyle"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="font-bold">Bold</option>
                <option value="italic">Italic</option>
                <option value="">Normal</option>
              </select>
            </div>
          ),
        },
        cartButtonTextColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonTextColor" className="block text-sm font-medium mb-2">
                Cart Button Text Color
              </label>
              <select
                id="cartButtonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        cartButtonBackgroundColor: {
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="cartButtonBackgroundColor" className="block text-sm font-medium mb-2">
                Cart Button Background Color
              </label>
              <select
                id="cartButtonBackgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        title: "",
        links: [
          { url: "/", text: "Home" },
          { url: "/products", text: "Products" },
          { url: "/contact", text: "Contact" },
          { url: "/about", text: "About" },
        ],
        logo: "https://stenzdigital.com/img/stenzdigitallogo.png",
        textColor: "text-black",
        fontSize: "text-xl",
        fontStyle: "",
        backgroundColor: "bg-white",
        cartButtonText: "Cart",
        cartButtonFontSize: "text-lg",
        cartButtonFontStyle: "font-bold",
        cartButtonTextColor: "text-white",
        cartButtonBackgroundColor: "bg-blue-500",
      },
      render: (props) => {
        return <Header {...props} />;
      },
    },
    ProductPreview: {
      fields: {
        title: {
          label: "Title",
          type: "text",
        },
        buttonLeftTitle: {
          label: "Button 1 Title",
          type: "text",
        },
        buttonRightTitle: {
          label: "Button 2 Title",
          type: "text",
        },
        titleFontSize: {
          label: "Title Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="titleFontSize" className="block text-sm font-medium mb-2">
                Title Font Size
              </label>
              <select
                id="titleFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        priceColor: {
          label: "Price Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceColor" className="block text-sm font-medium mb-2">
                Price Color
              </label>
              <select
                id="priceColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-gray-500">Gray</option>
                <option value="text-gray-100">Light Gray</option>
                <option value="text-gray-800">Dark Gray</option>
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-green-500">Green</option>
                <option value="text-red-500">Red</option>
                <option value="text-blue-500">Blue</option>
              </select>
            </div>
          ),
        },
        backgroundColor: {
          label: "Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="backgroundColor" className="block text-sm font-medium mb-2">
                Background Color
              </label>
              <select
                id="backgroundColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-white">White</option>
                <option value="bg-black">Black</option>
                <option value="bg-gray-100">Light Gray</option>
                <option value="bg-gray-800">Dark Gray</option>
              </select>
            </div>
          ),
        },
        textColor: {
          label: "Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="textColor" className="block text-sm font-medium mb-2">
                Text Color
              </label>
              <select
                id="textColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        productFontSize: {
          label: "Product Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productFontSize" className="block text-sm font-medium mb-2">
                Product Font Size
              </label>
              <select
                id="productFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-xl">Large</option>
                <option value="text-lg">Medium</option>
                <option value="text-md">Small</option>
              </select>
            </div>
          ),
        },
        productTextColor: {
          label: "Product Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="productTextColor" className="block text-sm font-medium mb-2">
                Product Text Color
              </label>
              <select
                id="productTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        descriptionFontSize: {
          label: "Description Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="descriptionFontSize" className="block text-sm font-medium mb-2">
                Description Font Size
              </label>
              <select
                id="descriptionFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        priceFontSize: {
          label: "Price Font Size",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="priceFontSize" className="block text-sm font-medium mb-2">
                Price Font Size
              </label>
              <select
                id="priceFontSize"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-lg">Large</option>
                <option value="text-md">Medium</option>
                <option value="text-sm">Small</option>
              </select>
            </div>
          ),
        },
        buttonBgColor: {
          label: "Button Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonBgColor" className="block text-sm font-medium mb-2">
                Button Background Color
              </label>
              <select
                id="buttonBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-yellow-500">Yellow</option>
                <option value="bg-blue-500">Blue</option>
                <option value="bg-green-500">Green</option>
                <option value="bg-red-500">Red</option>
                <option value="bg-gray-500">Gray</option>
                <option value="bg-black">Black</option>
              </select>
            </div>
          ),
        },
        buttonTextColor: {
          label: "Button Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="buttonTextColor" className="block text-sm font-medium mb-2">
                Button Text Color
              </label>
              <select
                id="buttonTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-white">White</option>
                <option value="text-black">Black</option>
                <option value="text-gray-500">Gray</option>
              </select>
            </div>
          ),
        },
        sizeBgColor: {
          label: "Size Button Background Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="sizeBgColor" className="block text-sm font-medium mb-2">
                Size Button Background Color
              </label>
              <select
                id="sizeBgColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="bg-gray-200">Gray</option>
                <option value="bg-white">White</option>
                <option value="bg-blue-200">Blue</option>
              </select>
            </div>
          ),
        },
        sizeTextColor: {
          label: "Size Button Text Color",
          type: "custom",
          render: ({ value, onChange }) => (
            <div className="mb-4">
              <label htmlFor="sizeTextColor" className="block text-sm font-medium mb-2">
                Size Button Text Color
              </label>
              <select
                id="sizeTextColor"
                onChange={(e) => onChange(e.target.value)}
                className="rounded-lg w-full py-2 px-4 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-300"
                required
                value={value}
              >
                <option value="text-black">Black</option>
                <option value="text-white">White</option>
                <option value="text-blue-800">Blue</option>
              </select>
            </div>
          ),
        },
      },
      defaultProps: {
        title: "Product Preview",
        buttonLeftTitle: "Buy Now",
        buttonRightTitle: "Add to Cart",
        titleFontSize: "text-xl",
        priceColor: "text-gray-500",
        backgroundColor: "bg-white",
        textColor: "text-black",
        productFontSize: "text-xl",
        productTextColor: "text-gray-900",
        descriptionFontSize: "text-base",
        priceFontSize: "text-lg",
        buttonBgColor: "bg-yellow-500",
        buttonTextColor: "text-white",
        sizeBgColor: "bg-gray-200",
        sizeTextColor: "text-black",
      },
      render: (props) => {
        return <ProductPreview {...props} />;
      },
    },
  },
};

export default eConfig;