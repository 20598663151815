import React, {useState, useEffect} from 'react';
import useAuthentication from '../../authenticate';
import { useParams } from 'react-router-dom';

const Cart = ({ 
  title, 
  backgroundColor, 
  textColor, 
  titleFontSize, 
  productFontSize, 
  priceColor, 
  priceFontSize, 
  buttonBgColor, 
  buttonTextColor, 
  buttonTitle 
}) => {
  const user = useAuthentication();
  const { sid } = useParams();
  const [currency, setCurrency] = useState('DKK ');

useEffect(() => {
  const getDetails = async () => {
    if (!user || !user.user || !user.user.sites) {
      return;
    }
  
    const sites = user.user.sites;
    const siteFound = sites.find(site => site.siteId === sid);
    const currency = siteFound?.currency;
    if(currency)
    {
      setCurrency(currency);
    }
  }
  getDetails();  
}, [user, sid])

  // Placeholder data for cart items
  const placeholderItems = Array(3).fill({
    name: 'Product',
    price: '10.00'
  });

  // Calculate subtotal
  const subtotal = placeholderItems.reduce((acc, item) => acc + parseFloat(item.price.replace('€', '')), 0);

  return (
    <div className={`p-4 ${backgroundColor}`}>
      {title && <h2 className={`text-center ${titleFontSize} ${textColor} mb-4`}>{title}</h2>}
      <div className="grid grid-cols-1 gap-4">
        {placeholderItems.map((item, index) => (
          <div key={index} className={`p-2 border rounded ${textColor} flex justify-between items-center`}>
            <div>
              <h3 className={`mb-1 ${productFontSize}`}>{item.name}</h3>
              <p className={`mb-1 ${priceFontSize} ${priceColor}`}>{currency}{item.price}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={`mt-4 p-4 border-t ${textColor}`}>
        <div className="flex justify-between items-center">
          <h3 className={`text-lg ${textColor}`}>Subtotal:</h3>
          <p className={`${priceColor}`}>{currency}{subtotal.toFixed(2)}</p>
        </div>
        <button className={`mt-4 p-3 ${buttonBgColor} ${buttonTextColor} rounded w-full`}>
          {buttonTitle}
        </button>
      </div>
    </div>
  );
};

export default Cart;
