import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import MediaModal from '../Media';

const ImageSection = ({ initialValue, onChange }) => {
  const [imageUrl, setImageUrl] = useState(initialValue);
  const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);

  const handleOpenMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const handleCloseMediaModal = () => {
    setIsMediaModalOpen(false);
  };

  const handleSelectMedia = (mediaUrl) => {
    setImageUrl(mediaUrl);
    onChange(mediaUrl);  // Notify parent of the change
    handleCloseMediaModal();
  };

  useEffect(() => {
    // Only call onChange if the imageUrl actually changes
    if (imageUrl !== initialValue) {
      onChange(imageUrl);
    }
  }, [imageUrl, initialValue, onChange]);

  return (
    <div className="relative w-full">
      {imageUrl ? (
        <>
          <img
            src={imageUrl}
            alt="Selected"
            className="w-full h-auto rounded-lg shadow-md"
          />
          <div className="absolute inset-0 flex justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
            <button
              onClick={handleOpenMediaModal}
              className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
            >
              <FaEdit />
            </button>
          </div>
        </>
      ) : (
        <div>
          <button
            onClick={handleOpenMediaModal}
            className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-700 focus:outline-none"
          >
            Choose Image
          </button>
        </div>
      )}
      {isMediaModalOpen && (
        <MediaModal
          isOpen={isMediaModalOpen}
          onClose={handleCloseMediaModal}
          onSelectMedia={handleSelectMedia}
        />
      )}
    </div>
  );
};

export default ImageSection;
